import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { usePhotoEditor } from 'react-photo-editor';
import InsideHeader from "./InsideHeader";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Loader from './Loader'

import { ToastContainer, toast } from 'react-toastify';
let { appname, lambda } = window.app;

const CustomPhotoEditor = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const [imagePath, setImg] = useState(location?.state?.filePath || "");
    const [item, setitem] = useState(location?.state?.item || "");
    const [showDocAlert, setShowDocAlert] = useState(false);
    const [file, setFile] = useState();
    const [activeLoad, setActiveLoad] = useState(false);
    const [selectFileName, setFileName] = useState();
    const [selectmimeType, setmimeType] = useState();
    const [fileEx, setFileExtension] = useState();
    const [errorMessage, setValidation] = useState(null);

    const [editFilename, setEditFilename] = useState(false);
    const [filenameDefault, setfilenameDefault] = useState('');

    useEffect(() => {
        // Example usage:
        const filename = item?.filename || id;
        const mimeType = item?.filetype || 'image/png';
        const fileformat = item?.fileformat

        setFileName(filename + "_v1")
        setmimeType(mimeType)
        setFileExtension(fileformat)

        setfilenameDefault(filename)

        urlToFile(imagePath, filename, mimeType).then(file => {
            console.log('File created:', file);
            // Now you can use the File object (e.g., upload it, process it, etc.)
            setFile(file)
        });
    }, [imagePath]);

    const setFileData = (e) => {
        if (e?.target?.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const urlToFile = async (url, filename, mimeType) => {
        // Fetch the image from the URL
        const response = await fetch(url);
        // Convert the response to a Blob
        const blob = await response.blob();

        // Convert the Blob to a File
        const file = new File([blob], filename, { type: mimeType });

        return file;
    }

    const canvasToFile = async (canvas, filename, selectmimeType) => {
        return new Promise((resolve, reject) => {
            canvas.toBlob(async (blob) => {
                console.log('newFile', blob)
                if (blob) {
                    const newFile = new File([blob], filename, { type: blob.type });
                    console.log('newFilenewFilenewFile', newFile)
                    resolve(newFile);
                } else {
                    resolve(null)
                }
            }, selectmimeType);

        })
    }

    const generateEditedFile = () => {
        return new Promise((resolve, reject) => {
            const canvas = canvasRef.current;
            if (!canvas || !file) {
                resolve(null);
                return;
            }

            const fileExtension = (file.name.split('.').pop() || '').toLowerCase();
            let mimeType;
            switch (fileExtension) {
                case 'jpg':
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                default:
                    mimeType = 'image/png';
            }

            canvas.toBlob(async (blob) => {
                if (blob) {
                    const newFile = new File([blob], file.name, { type: blob.type });
                    resolve(newFile);
                } else {
                    resolve(null);
                }
            }, mimeType);
        });
    };


    // Save edited image
    const handleSaveImage = async (e, type) => {

        if (selectFileName == "") {
            setTimeout(() => {
                setValidation(null)
            }, 2000);
            setValidation('Please enter File name')
            return false
        }
        
        // const canvas = canvasRef.current;
        let editedFile = await generateEditedFile()
        setActiveLoad(true)
        // let editedFile = await canvasToFile(canvas, selectFileName, selectmimeType)
        // console.log('editedFile2', editedFile)

        let latestFilename = selectFileName + "." + fileEx
        let uploadFilePath = location?.state?.item?.filepath;
        let bucket = window.site.common.resourceBucket; // Use resource bucket for images
        if (type == "saveCopy") {

            // Find the position of the last slash
            const lastSlashIndex = uploadFilePath.lastIndexOf('/');

            // Separate the path
            const beforeLastSlash = uploadFilePath.slice(0, lastSlashIndex); // Everything before the last slash
            const afterLastSlash = uploadFilePath.slice(lastSlashIndex + 1); // Everything after the last slash

            uploadFilePath = beforeLastSlash + "/" + latestFilename
        }

        const data = {
            source_bucket: bucket, // Use the correct bucket based on file type
            sourcepath: uploadFilePath
        };

        // console.log('data', data)

        axios.post(
            `${lambda}/uploadFiles?appname=${appname}`,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(uploadUrlResponse => {
            // console.log('uploadUrlResponse', uploadUrlResponse)
            if (uploadUrlResponse.data && uploadUrlResponse.data.result) {
                const uploadUrl = uploadUrlResponse.data.result;

                return axios.put(uploadUrl, editedFile, {
                    headers: {
                        'Content-Type': file.type,
                        'Cache-Control': 'no-cache'
                    }
                }).then(uploadResponse => {
                    // console.log('ssssccc', uploadResponse)
                    if (type == "saveCopy") {
                        const ObjectID = require("bson-objectid");
                        const uniqueId = new ObjectID().toString();

                        const newFile = {
                            filename: latestFilename,
                            filepath: uploadFilePath,
                            fileid: uniqueId,
                            filetype: file.type,
                            filesize: file.size,
                            fileformat: fileEx
                        };
                        createContentFile(newFile)
                    } else {
                        setActiveLoad(false)
                        navigate(-1)

                    }

                });
            }
        })

        // setFile(editedFile);
    };

    const createContentFile = (newFile) => {


        const clientid = localStorage.getItem('clientid');
        axios.post(
            `${lambda}/clientContent?appname=${appname}&clientid=${clientid}`,
            newFile
        ).then(clientContentResponse => {
            setActiveLoad(false)
            setShowDocAlert(false)
            if (clientContentResponse.status === 200) {

                navigate(-1)
            } else {
                throw new Error('Failed to add user file');
            }
        });
    }

    const saveFilename = (e, fileid) => {

        if (!filenameDefault.trim())  {
            toast.error('Please enter an name');
            return;
          }

        let filename = {
            "filename": filenameDefault
        }
        axios.post(
            `${lambda}/updateClientFile?appname=${appname}&fileid=${fileid}`,
            filename
        ).then(filenameResponse => {
            setActiveLoad(false)
            setEditFilename(false)
            
        });
    }


    const {
        canvasRef,
        imageSrc,
        brightness,
        setBrightness,
        contrast,
        setContrast,
        saturate,
        setSaturate,
        grayscale,
        setGrayscale,
        rotate,
        setRotate,
        flipHorizontal,
        setFlipHorizontal,
        flipVertical,
        setFlipVertical,
        zoom,
        setZoom,
        handlePointerDown,
        handlePointerUp,
        handlePointerMove,
        handleWheel,
        downloadImage,
        resetFilters,
    } = usePhotoEditor({ file });

    const handleClose = () => {
          navigate(-1);
      };

    return (
        <div id="page-top">
            {/* <InsideHeader flag={true} /> */}
          
                <div className="inner-body blankpage photo_editor">
                    {activeLoad && <Loader />}
                    <div className="row">
                        <div className="col-md-9">
                        <div className="image_block">

                            {imagePath && (
                                <div className="canvas-container">
                                <span className="material-symbols-outlined" onClick={handleClose}>close</span>
                                    <canvas className="canvas" ref={canvasRef}
                                    // onMouseDown={handlePointerDown}
                                    // onMouseMove={handlePointerMove}
                                    // onMouseUp={handlePointerUp}
                                    // onWheel={handleWheel}
                                    style={{ "width": "100%" }}
                                    />
                                </div>
                            )}
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="edit_option">

                            <div className="image_file_name">
                            {editFilename ?
                            
                            <input name="editFilename" value={filenameDefault} onChange={(e) => setfilenameDefault(e.target.value) } />
                            :  <h3>{filenameDefault} </h3>
                            }
                           {!editFilename ?
                            <button className="btn btn-primary" onClick={(e) => setEditFilename(true)}> 
                            <span className="material-symbols-outlined">edit</span></button> :  
                            <button className="btn btn-primary save" onClick={(e) => saveFilename(e, location?.state?.item?.fileid)}> 
                           
                           Save </button>
                           }</div>
                          
                            <div className="controls">
                                <div className="brightness">
                               
                                    <label>Brightness</label>
                                    <div className="brightness_input">
                                    <input
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={brightness}
                                        onChange={(e) => setBrightness(Number(e.target.value))}
                                    />
                                </div>
                                </div>

                                <div className="brightness">
                                
                                    <label>Contrast</label>
                                    <div className="brightness_input">
                                    <input
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={contrast}
                                        onChange={(e) => setContrast(Number(e.target.value))}
                                    />
                                </div>
                                </div>

                                <div className="brightness">
                                
                                    <label>Saturate</label>
                                    <div className="brightness_input">
                                    <input
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={saturate}
                                        onChange={(e) => setSaturate(Number(e.target.value))}
                                    />
                                </div>
                                </div>

                                <div className="brightness">
                                
                                    <label>Grayscale</label>
                                    <div className="brightness_input">
                                    <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={grayscale}
                                        onChange={(e) => setGrayscale(Number(e.target.value))}
                                    />
                                </div>
                                </div>

                                <div className="brightness">
                                
                                    <label>Rotate</label>
                                    <div className="brightness_input">
                                    <input
                                        type="range"
                                        min="0"
                                        max="360"
                                        value={rotate}
                                        onChange={(e) => setRotate(Number(e.target.value))}
                                    />
                                </div>
                                </div>
                                {/* 
                        <div>
                            <label>Zoom</label>
                            <input
                                type="range"
                                min="0.1"
                                max="3"
                                step="0.1"
                                value={zoom}
                                onChange={(e) => setZoom(Number(e.target.value))}
                            />
                        </div> */}
 <div className="d-flex align-items-center justify-content-between">
                                <div className="flip_horizontal">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={flipHorizontal}
                                            onChange={(e) => setFlipHorizontal(e.target.checked)}
                                        />
                                        Flip Horizontal
                                    </label>
                                </div>

                                <div className="flip_horizontal">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={flipVertical}
                                            onChange={(e) => setFlipVertical(e.target.checked)}
                                        />
                                        Flip Vertical
                                    </label>
                                </div>
                                </div>

                                <div className="buttons mt-3">
                                    <div className="btn-group">
                                        <button onClick={(e) => handleSaveImage(e, 'save')} className="btn gradietn_btn rounded-pill me-2">Save</button>

                                        <button onClick={e => setShowDocAlert(true)} className="btn  me-2 btn_outline">Save as copy</button>
 
                                        <button onClick={(e) => resetFilters(e)} className="btn  btn_outline">Reset</button>
                                    </div>


                                </div>







                            </div>

                        </div>
                        </div>
                    </div>

                    <ToastContainer />

                    <Modal className="access-denied delete_popup" show={showDocAlert}>
                        <div className="modal-body">
                            <button className="close-btn"><span className="material-icons" onClick={e => setShowDocAlert(false)}>close</span></button>
                            <span className="material-icons delete-icon">description</span>
                            <h3>File Name</h3>
                            <div className="form-group">
                                <input type="text" name="name" onChange={(e) => setFileName(e.target.value)} className="form-control" value={selectFileName} placeholder="Enter File Name" />
                                <p className='text-danger'>{errorMessage}</p>
                            </div>
                            <div className="popup-footer">
                                <button className="fill_btn" onClick={e => handleSaveImage(e, 'saveCopy')} > Save As Copy </button>
                            </div>
                        </div>
                    </Modal>
                </div>
           
        </div>
    );
};

export default CustomPhotoEditor;
