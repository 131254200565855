import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import InsideHeader from './InsideHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
let { appname, lambda } = window.app;

const MyAccount = (props) => {
    const [user, setUser] = useState({ name: '', personalemail: '', personalphone: '' });
    const [errors, setErrors] = useState({});
    const [passwordInput, setPasswordInput] = useState({
        password: '',
        confirmPassword: '',
        oldPassword: ''
    });

    const [passwordError, setPasswordErr] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [number, setNumber] = useState(false);
    const [upper, setUpper] = useState(false);
    const [limit, setLimit] = useState(false);

    const [lower, setLower] = useState(false);
    const [special, setSpecial] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [activeLoad, setActiveLoad] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        } else {
            fetchUserData();
        }
    }, [navigate]);

    const fetchUserData = async () => {

        try {
            const clientid = localStorage.getItem('clientid');
            const token = localStorage.getItem('token');
            console.log("clientid---->", clientid)
            const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);
            if (response.data?.result === "Client not found") {
                localStorage.removeItem("token");
                localStorage.removeItem("currentSessionClientTime");
                localStorage.removeItem("clientid");
                navigate('/signin');
            } else if (response.status === 200 && response.data?.result?.[0]) {
                setUser(response.data.result[0]);
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (err) {
            console.error('Error fetching user data:', err);
            setError(err.message);
        }
    };


    const validate = () => {
        let validationErrors = {};
        if (!user.name) {
            validationErrors.name = 'Name is required';
        } else if (user.name.length < 2) {
            validationErrors.name = 'Name must be at least 2 characters';
        }
        if (user.personalphone && !/^\d{10}$/.test(user.personalphone)) {
            validationErrors.personalphone = 'Phone number is invalid';
        }
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {

            try {
                setActiveLoad(true)
                const clientid = localStorage.getItem('clientid');
                const token = localStorage.getItem('token');
                const payload = {
                    name: user?.name,
                    personalphone: user?.personalphone,

                };
                await axios.post(`${lambda}/client?appname=${appname}&clientid=${clientid}`, payload); // Replace with your updateUser API endpoint
                setActiveLoad(false)
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'Profile updated successfully.',
                //     icon: 'success',
                //     confirmButtonText: 'OK'
                // });
                toast.success("Profile updated successfully.", {
                    position: "bottom-center",
                    title: 'Success!'
                });

            } catch (error) {
                setActiveLoad(false)
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Something went wrong. Please try again later.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                // });
                toast.error("Client not found. Please sign up.", {
                    position: "bottom-center"
                });
            }

        } else {
            setActiveLoad(false)
            setErrors(validationErrors);
        }
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordInput(prevState => ({ ...prevState, [name]: value }));
    };
    const handleValidation = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        //for password 
        if (passwordInputFieldName === 'password' || passwordInputFieldName === 'oldPassword') {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            const passwordLength = passwordInputValue.length;
            const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
            const digitsPassword = digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword = minLengthRegExp.test(passwordInputValue);
            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
                setTimeout(() => setPasswordErr(""), 4000);

            }
            // else if (!uppercasePassword) {
            //     errMsg = "At least one Uppercase";
            // } else if (!lowercasePassword) {
            //     errMsg = "At least one Lowercase";
            // } else if (!digitsPassword) {
            //     errMsg = "At least one digit";
            // } else if (!specialCharPassword) {
            //     errMsg = "At least one Special Characters";
            // } else if (!minLengthPassword) {
            //     errMsg = "At least minumum 8 characters";
            // } else {
            //     errMsg = "";
            // }
            if (uppercasePassword) {
                var element = document.getElementById("err1");
                element.classList.add("vaild");
                setUpper(true);
            } else {
                var element = document.getElementById("err1");
                element.classList.remove("vaild");
                setUpper(false);
            }
            if (lowercasePassword) {
                var element = document.getElementById("err");
                element.classList.add("vaild");
                setLower(true);
            } else {
                var element = document.getElementById("err");
                element.classList.remove("vaild");
                setLower(false);
            }
            if (digitsPassword) {
                var element = document.getElementById("err2");
                element.classList.add("vaild");
                setNumber(true);
            } else {
                var element = document.getElementById("err2");
                element.classList.remove("vaild");
                setNumber(false);
            }
            if (specialCharPassword) {
                var element = document.getElementById("err3");
                element.classList.add("vaild");
                setSpecial(true)
            } else {
                var element = document.getElementById("err3");
                element.classList.remove("vaild");
                setSpecial(false)
            }
            if (minLengthPassword) {
                var element = document.getElementById("err4");
                element.classList.add("vaild");
                setLimit(true)
            } else {
                var element = document.getElementById("err4");
                element.classList.remove("vaild");
                setLimit(false)
            }

            if (limit && upper && special && lower && number && confirmPasswordError === "") {
                setError("")
            }

            setPasswordErr(errMsg);
        }
        // for confirm password
        // if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {

        //     if (passwordInput?.confirmPassword !== passwordInput?.password) {
        //         setConfirmPasswordError("Confirm password is not matched");
        //     } else {
        //         setConfirmPasswordError("");
        //     }

        // }
    }
    const handleRemoveclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.remove("ins-dsp-none");
    }
    // const handleValidation = (e) => {
    //     const { name, value } = e.target;
    //     const uppercaseRegExp = /(?=.*?[A-Z])/;
    //     const lowercaseRegExp = /(?=.*?[a-z])/;
    //     const digitsRegExp = /(?=.*?[0-9])/;
    //     const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    //     const minLengthRegExp = /.{8,}/;

    //     if (name === 'password' || name === 'oldPassword') {
    //         setPasswordError(value.length === 0 ? "Password is empty" : "");
    //         setUpper(uppercaseRegExp.test(value));
    //         setLower(lowercaseRegExp.test(value));
    //         setNumber(digitsRegExp.test(value));
    //         setSpecial(specialCharRegExp.test(value));
    //         setLimit(minLengthRegExp.test(value));
    //     }

    //     if (name === 'confirmPassword' && value !== passwordInput.password) {
    //         setConfirmPasswordError("Confirm password does not match");
    //     } else {
    //         setConfirmPasswordError("");
    //     }
    // };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };
    const handleAddclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.add("ins-dsp-none");
        setConfirmPasswordError("");
    }

    const handlePwdGenerate = (e) => {
        e.preventDefault();
        if (user?.userpwd?.length > 0) {
            if (passwordInput?.password == "") {
                setError("Please Enter Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput?.confirmPassword == "") {
                setError("Please Enter Confirm Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput?.oldPassword == "") {
                setError("Please Enter Old Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput?.oldPassword == passwordInput?.password) {
                setError("old and new passwords are must not be same");
                setTimeout(function () { setError("") }, 3000);

            }


            else if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password does not match");
                setTimeout(() => setError(""), 4000);
            } else if (!limit || !upper || !special || !lower || !number) {
                setError("Please enter the password as per the instructions");
                setTimeout(() => setError(""), 4000);
            } else {
                pwdGenerate();
            }
        } else {
            if (passwordInput?.password == "") {
                setError("Please Enter Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput?.confirmPassword == "") {
                setError("Please Enter Confirm Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password does not match");
                setTimeout(() => setError(""), 4000);
            } else if (!limit || !upper || !special || !lower || !number) {
                setError("Please enter the password as per the instructions");
                setTimeout(() => setError(""), 4000);
            } else {
                pwdGenerate();
            }
        }
    };

    const handleConfirm = (evnt) => {
        const passwordInputFieldName = evnt.target.name;
        if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {

            // if (passwordInput?.confirmPassword !== passwordInput?.password) {
            //     setConfirmPasswordError("Confirm password is not matched");
            // } else {
            //     setConfirmPasswordError("");
            // }

        }
    }
    const pwdGenerate = async () => {
        console.log("Processing password change...");
        const clientid = localStorage.getItem('clientid');
        const token = localStorage.getItem('token');
        try {
            setIsResendLoading(true)
            const isChangePwd = user?.userpwd?.length > 0;


            const payload = {
                emailid: user.emailid,
                password: passwordInput.password,
                ...(isChangePwd && { oldPassword: passwordInput.oldPassword })
            };

            const response = await axios.post(
                `${lambda}/setPassword?appname=${appname}&clientid=${clientid}`,
                payload
            );

            if (response.data.result === "old password is wrong") {
                setIsResendLoading(false)
                setError("Old password is incorrect");
                setTimeout(() => setError(""), 4000);
            } else if (response.data.result === "password updated successfully") {
                setIsResendLoading(false)
                // Swal.fire({
                //     title: 'Success!',
                //     text: user?.userpwd ? 'Password changed successfully.' : 'Password created successfully.',
                //     icon: 'success',
                //     confirmButtonText: 'OK'
                toast.success(user?.userpwd ? 'Password changed successfully.' : 'Password created successfully.', {
                    position: "bottom-center",
                    title: 'Success!'
                });


                setPasswordInput({
                    password: '',
                    confirmPassword: '',
                    oldPassword: ''
                })
                fetchUserData()

            } else {
                setIsResendLoading(false)
                setError("Something went wrong. Please try again.");

            }

            setActiveLoad("");
        } catch (error) {
            setIsResendLoading(false)
            setActiveLoad("");
            setError("Something went wrong. Please try again.");

        }
    };

    console.log('user ', user)

    return (
        <div className="inner-body">

            <InsideHeader flag={true} />
            <ToastContainer
                position="bottom-right"
            />

            <div className="my-profile">
                <div className="banner-section">
                    <img alt="cover" className="banner-img" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/profile-banner.png" />
                </div>

                <div className="cards-section">
                    <div className="left-side">
                        <div className="left-side-section">

                            <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#account" role="tab">
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Account Settings</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#password" role="tab">
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Password Settings</span>
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content pt-15 text-muted">
                                <div className="tab-pane active" id="account" role="tabpanel">

                                    <div className="form-set">
                                        <div className="form_section">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                value={user.name}
                                                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                                onFocus={handleFocus}
                                                                className="form-control"
                                                            />
                                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input
                                                                type="email"
                                                                name="personalemail"
                                                                value={user.emailid}
                                                                disabled
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Phone number</label>
                                                            <input
                                                                type="text"
                                                                name="personalphone"
                                                                value={user.personalphone}
                                                                onChange={(e) => {
                                                                    const onlyDigits = e.target.value.replace(/\D/g, "");
                                                                    setUser({ ...user, personalphone: onlyDigits });
                                                                }}
                                                                onFocus={handleFocus}
                                                                className="form-control"
                                                            />
                                                            {errors.personalphone && <div className="text-danger">{errors.personalphone}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">

                                                        <button type="submit" className="btn_outline gradietn_btn mr-10">{activeLoad && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}Update</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>


                                <div className="tab-pane password" id="password" role="tabpanel">

                                    <div className="form-set">
                                        <div className="form_section">
                                            {/* <h1>{user?.userpwd?.length > 0 ? "Change Password" : "Create Password"}</h1> */}
                                            <div className="row">

                                                {user?.userpwd?.length > 0 &&
                                                    <div className="col-md-6">

                                                        <div className="form-group">
                                                            <label>Old Password</label>
                                                            <input
                                                                type={passwordShown ? "text" : "password"}
                                                                name="oldPassword"
                                                                value={passwordInput.oldPassword}
                                                                onChange={handlePasswordChange}
                                                                // onKeyUp={handleValidation}
                                                                className="form-control capitalize-text"
                                                            />
                                                            <p className="text-danger">{oldPasswordError}</p>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>New Password</label>
                                                        <input
                                                            type={passwordShown ? "text" : "password"}
                                                            name="password"
                                                            value={passwordInput.password}
                                                            onChange={handlePasswordChange}
                                                            onKeyUp={handleValidation}
                                                            onBlur={(e) => { handleAddclass(e) }}
                                                            onFocus={(e) => { handleRemoveclass(e) }}
                                                            className="form-control"
                                                        />
                                                        <p className="text-danger">{passwordError}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group mb-1">
                                                        <label>Confirm Password</label>
                                                        <input
                                                            type={passwordShown ? "text" : "password"}
                                                            name="confirmPassword"
                                                            value={passwordInput.confirmPassword}
                                                            onChange={handlePasswordChange}
                                                            onKeyUp={handleConfirm}
                                                            onBlur={(e) => { handleAddclass(e) }}
                                                            onFocus={(e) => { handleRemoveclass(e) }}
                                                            className="form-control"
                                                        />
                                                        <p className="text-danger">{confirmPasswordError}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-1">

                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            id="showPassword"
                                                            onChange={() => setPasswordShown(!passwordShown)}
                                                        />
                                                        <label className="mb-0 ms-1">   Show Password</label>

                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">


                                                    <button
                                                        onClick={handlePwdGenerate}
                                                        className="btn_outline gradietn_btn mr-10"
                                                    >
                                                        {isResendLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                                        {user?.userpwd?.length > 0 ? 'Change Password' : 'Create Password'}
                                                    </button>



                                                    {error && <p className="text-danger">{error}</p>}

                                                   <div className='account-page'>
                                                        <div className="create-password-instruction ins-dsp-none" id="instruction">
                                                            <p className="error" id="err">{lower ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain a lower case letter</p>
                                                            <p className="error" id="err1"> {upper ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain an upper case letter</p>
                                                            <p className="error" id="err2">  {number ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain a number</p>
                                                            <p className="error" id="err3"> {special ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain a special character or a space</p>
                                                            <p className="error" id="err4"> {limit ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain at least 8 characters</p>
                                                        </div>
                                                        </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                    <div className="right-side">
                        <div className="avatar-img-block">
                            <p>{user?.name && user?.name.substring(0, 2).toUpperCase()}</p>
                        </div>
                        <div className="avatar-text-block">
                            <h6 className="username">{user?.name}</h6>
                            <p>{user?.planType && user?.planType.toUpperCase() || ""}</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="container">
                <div className="account-page">


                    <div className="card">
                        <div className="card-body">
                            <h1>My Account</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={user.name}
                                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                onFocus={handleFocus}
                                                className="form-control"
                                            />
                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                name="personalemail"
                                                value={user.emailid}
                                                disabled
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>phone number</label>
                                            <input
                                                type="text"
                                                name="personalphone"
                                                value={user.personalphone}
                                                onChange={(e) => {
                                                    const onlyDigits = e.target.value.replace(/\D/g, "");
                                                    setUser({ ...user, personalphone: onlyDigits });
                                                }}
                                                onFocus={handleFocus}
                                                className="form-control"
                                            />
                                            {errors.personalphone && <div className="text-danger">{errors.personalphone}</div>}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn gradietn_btn rounded-pill">{activeLoad && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}Update</button>
                                        </div></div>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="change-password">
                                <h1> {user?.userpwd?.length > 0 ? "Change Password" : "Create Password"}</h1>
                                <div className="row">
                                    <div className="col-md-7">
                                        {user?.userpwd?.length > 0 &&
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <label>Old Password</label>
                                                    <input
                                                        type={passwordShown ? "text" : "password"}
                                                        name="oldPassword"
                                                        value={passwordInput.oldPassword}
                                                        onChange={handlePasswordChange}

                                                        className="form-control"
                                                    />
                                                    <p className="text-danger">{oldPasswordError}</p>
                                                </div>
                                            </div>}

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    name="password"
                                                    value={passwordInput.password}
                                                    onChange={handlePasswordChange}
                                                    onKeyUp={handleValidation}
                                                    onBlur={(e) => { handleAddclass(e) }}
                                                    onFocus={(e) => { handleRemoveclass(e) }}
                                                    className="form-control"
                                                />
                                                <p className="text-danger">{passwordError}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Confirm Password</label>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    name="confirmPassword"
                                                    value={passwordInput.confirmPassword}
                                                    onChange={handlePasswordChange}
                                                    onKeyUp={handleConfirm}
                                                    onBlur={(e) => { handleAddclass(e) }}
                                                    onFocus={(e) => { handleRemoveclass(e) }}
                                                    className="form-control"
                                                />
                                                <p className="text-danger">{confirmPasswordError}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group d-flex align-items-center check_box">
                                                <input
                                                    type="checkbox"
                                                    id="showPassword"
                                                    onChange={() => setPasswordShown(!passwordShown)}
                                                />
                                                <label>Show Password</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button
                                                onClick={handlePwdGenerate}
                                                className="btn gradietn_btn rounded-pill"
                                            >
                                                {isResendLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                                {user?.userpwd?.length > 0 ? 'Change Password' : 'Create Password'}
                                            </button>



                                            {error && <p className="text-danger">{error}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="create-password-instruction ins-dsp-none" id="instruction">
                                            <p className="error" id="err">{lower ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain a lower case letter</p>
                                            <p className="error" id="err1"> {upper ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain an upper case letter</p>
                                            <p className="error" id="err2">  {number ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain a number</p>
                                            <p className="error" id="err3"> {special ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain a special character or a space</p>
                                            <p className="error" id="err4"> {limit ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain at least 8 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div> */}
            <Footer />
        </div>
    );
};

export default MyAccount;