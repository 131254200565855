// PaymentPage.js
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import InsideHeader from './InsideHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CommonContext } from './CommonContext';
let { appname, lambda } = window.app;

// Use your test publishable key here
const stripePromise = loadStripe('pk_test_RbHkYq9QbbfEcPNMhohsZIMF00DXHJgRa2');

const CheckoutForm = ({ totalAmount, formData }) => {
  // const location = useLocation();
  console.log(formData, "formData")
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const { totalAmount} = location.state || {};
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);


    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    const clientid = localStorage.getItem('clientid');
    const token = localStorage.getItem('token');
    try {

      let payload = location?.state?.payload;
      console.log("payload",payload)
      payload['address'] = formData;
      localStorage.setItem('billingAddress', JSON.stringify(formData))
      const response = await axios.post(`${lambda}/payment?appname=${appname}&clientid=` + clientid,
        payload
        // { totalAmount }
      );
      const { result: clientSecret } = response.data;

      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (confirmError) {
        setError(confirmError.message);
        setLoading(false);
        await axios.post('/update-transaction-status', {
          paymentIntentId: paymentIntent.id,
          status: 'failed',
        });
        // Swal.fire('Error', confirmError.message, 'error');

        setTimeout(function () { setError("") }, 3000);

      } else {
        // setError(null);
        // let presets = [];
        // if (payload?.services?.[0]?.profile.length !== 0) {
        //   presets = payload?.services?.[0]?.profile.map((profile) => ({
        //     Preset: profile.Preset,
        //     NameModifier: profile.NameModifier,
        //   }));
        // } else if (payload?.services?.[0]?.selectedSubtitles) {
        //   presets = payload?.services?.[0]?.selectedSubtitles;
        // } else if (payload?.services?.[0]?.selectedLanguage) {
        //   presets = payload?.services?.[0]?.selectedLanguage
        // }

        // const successPayload = {
        //   "params": {
        //     "Destination": window?.site?.common?.proxiesBucket + "/",
        //     "NameModifier": payload?.services?.[0]?.filename,
        //     "appName": payload?.services?.[0]?.appname,
        //     "clientFileId": payload?.services?.[0]?.clientFileId,
        //     "imagesbucket": window?.site?.common?.imagesBucket,
        //     "myBucket": window?.site?.common?.sourceBucket,
        //     "path": payload?.services?.[0]?.filepath + payload?.services?.[0]?.filename,
        //     "proxiesBucket": window?.site?.common?.proxiesBucket
        //   },
        //   "presets": presets
        // }
        // const payload2 = {
        //   "params": {
        //     "Destination": "fusionshift-mediaservices-dev-resources/proxies/",
        //     "NameModifier": "66a8ac7581b1e3416402395c_TRAILERS_1722330229435.mp4",
        //     "appName": "fusionshiftServicesDev",
        //     "contentfileid": "66a8ac7581b1e3416402395c",
        //     "contentid": "66a897b715a5a1e5cf00aaaf",
        //     "imagesbucket": "fusionshift-mediaservices-dev-resources/images",
        //     "myBucket": "fusionshift-mediaservices-dev-source",
        //     "path": "fusionshift-mediaservices-dev-source/667a555ca2fe2cf8f354a6bf/files/66c5bac682d622add4b36c41_video_1724234438628.mp4",
        //     "proxiesBucket": "fusionshift-mediaservices-dev-resources/proxies"
        //   },
        //   "presets": [
        //     {
        //       "Preset": "480P-2.5Mbps", "NameModifier": "_480p"
        //     },
        //     {
        //       "Preset": "720P-6.5Mbps", "NameModifier": "_720p"
        //     },
        //     {
        //       "Preset": "1080P-8.5Mbps", "NameModifier": "_1080p"
        //     }
        //   ]
        // }
        // axios.post('https://1mgtsjq0kj.execute-api.us-east-1.amazonaws.com/latest/pushtoElemental?appname=fusionshiftServicesDev&clientid=' + clientid,
        //   successPayload
        //   // { totalAmount }
        // ).then(res => {
        //   toast.success("Payment Successful!", {
        //     position: "bottom-center",
        //     title: 'Success!'
        //   });
        //   setTimeout(function () {
        //     const id = payload?.services?.[0]?.clientFileId
        //     navigate(`/editcontent/${id}`);
        //   }, 3000)

        // })
        // Payment successful, handle post-payment actions
        // Swal.fire('Success', 'Payment Successful!', 'success');
        // await axios.post('/update-transaction-status', {
        //   paymentIntentId: paymentIntent.id,
        //   status: 'succeeded',
        // });
        // navigate('/editcontent');
        toast.success("Payment Successful!", {
          position: "bottom-center",
          title: 'Success!'
        });
        setTimeout(function () {
          // const id = payload?.services?.[0]?.clientFileId
          navigate(`/all-files`);
        }, 5000)


      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  // console.log("totalAmountlll",totalAmount)
  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {/* options={{ hidePostalCode: true }} */}
       
      <button className="btn btn-primary pay" type="submit" disabled={!stripe || loading}>
        {loading ? 'Processing...' : `Pay $${totalAmount}`}
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

const PaymentPage = () => {
  const { userData, setUserData } = useContext(CommonContext);
  let billingAddress = localStorage.getItem('billingAddress') || null;
  if (billingAddress) {
    billingAddress = JSON.parse(billingAddress)
  }
  console.log(userData, "aassa")

  const [formData, setFormData] = useState({
    firstName: billingAddress?.firstName || userData?.name,
    lastName: billingAddress?.lastName || '',
    email: billingAddress?.email || userData?.emailid,
    streetAddress1: billingAddress?.streetAddress1 || "",
    streetAddress2: billingAddress?.streetAddress2 || "",
    state: billingAddress?.state || "",
    city: billingAddress?.city || "",
    zipCode: billingAddress?.zipCode || "",
    phone: billingAddress?.phone || "",
    billingSameAsShipping: billingAddress?.billingSameAsShipping || false,
  });
  const location = useLocation();
  const { totalAmount } = location?.state || {}; // Retrieve totalAmount from location.state
  // const fetchUserData = async () => {
  //   console.log('Fetching user data...');
  //   try {
  //     const clientid = localStorage.getItem('clientid');
  const token = localStorage.getItem('token');
  //     console.log("clientid---->", clientid)
  //     const response = await axios.get(`${lambda}/client?appname=fusionshiftServicesDev&clientid=${clientid}`);

  //     if (response.status === 200 && response.data?.result?.[0]) {
  //       setUser(response.data.result[0]);
  //     } else {
  //       throw new Error('Unexpected response format');
  //     }
  //   } catch (err) {
  //     console.error('Error fetching user data:', err);
  //   }
  // };

  // useEffect(() => {
  //   fetchUserData();

  // }, []);

  // if (totalAmount === undefined) {
  //   // Handle the case where totalAmount is not provided
  //   return <div>Error: Grand total not found</div>;
  // }


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  return (
    <div id="page-top">
      <InsideHeader />
      <ToastContainer
        position="bottom-right"
      />


      <div className="inner-body payment">
        <div className="inner-wrapper">

          <div className="billing_address">
            <div className="card">
              <div className="card-body">
                <h4>Billing Address</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        placeholder="Enter First Name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        placeholder="Enter Last Name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter Email Address"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Street Address</label>
                      <input
                        type="text"
                        name="streetAddress1"
                        value={formData.streetAddress1}
                        onChange={handleInputChange}
                        placeholder="Enter Street Address"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="streetAddress2"
                        value={formData.streetAddress2}
                        onChange={handleInputChange}
                        placeholder="Enter Street Address"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State/Province</label>
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        placeholder="Enter State/Province"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        placeholder="Enter City"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Zip/Postal Code</label>
                      <input
                        type="number"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        placeholder="Enter Zip/Postal Code"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Enter Phone Number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="billingSameAsShipping"
                          checked={formData.billingSameAsShipping}
                          onChange={handleInputChange}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          My billing and shipping address are the same
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="billing_summary">
            <div className="card">
              <div className="card-body">
                <h4>Billing Summary</h4>
                <div className="total">
                  <p>Total</p>
                  <p>{`$ ${totalAmount}`}</p>
                </div>
              </div>
            </div>
            <h4 className="mt-4">Payment Method</h4>
            <div className="card payment_method">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p>Pay with Credit Card</p>
                  <img src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/credit-card.png" alt="Logo" />
                </div>
                <Elements stripe={stripePromise}>
                  <CheckoutForm totalAmount={totalAmount} formData={formData} />
                </Elements>
              </div>
            </div>
            <p className="bank_security">
              <span className="material-icons">lock</span> We protect your payment information using encryption to provide bank-level security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


export default PaymentPage;
