import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
let { appname, lambda } = window.app;

const Settings = () => {
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);


      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  return (
    <div>
      <div id="page-top">
        <Navbar />
        {/* <div className='right-section'> */}
        <div className="inner-body">
          <div className="inner-wrapper">
            <Sidebar />
            <div className='right-section'>
              <div className="page-title">
                <div className='d-flex align-items-center'>
                  <h1 className="sm-heading lite-text">Settings</h1>
                  {/* <button className="btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0  ml-15" >
                    <span className="d-flex align-items-center">
                      <span>Create</span>
                    </span>
                  </button> */}
                </div>
                <div className="d-flex align-items-center">
                  {/* <label className="form-label">Sort By</label>
                  <select className="form-select" name="sortby">
                    <option value="">Select</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select> */}
                </div>
              </div>
              <div className="content-block blankpage">
               
                  <div className="text-center transform-center">
                 
                      <img src={`${image}images/app/images/default-img.png`} alt="comingsoon" />
                   
                    <h3 >
                      COMING SOON
                    </h3>
                    <p>We're working hard to get this page up and running. Stay tuned!</p>

                  </div>
                </div>

                <Footer/>
            </div>


          </div>
        </div>
      
      </div>

    </div>

  )
}
export default Settings;