import React from 'react';
// import ComingSoon from './ComingSoon';
import InsideHeader from './InsideHeader';
import Footer from './Footer';
let { appname, lambda } = window.app;

const Terms = () => {

  return (
    <div className='inner_page'>
      <InsideHeader flag={true} />
      <div className='content_block'>
        <div className='content'>
          <h1>Terms of Use</h1>

          <h3>Effective Date: 11-22-2024</h3>
          <p>Welcome to shareclix.app ("the Service"). These Terms of Use ("Terms") govern your use of www.shareclix.app, including its mobile application, website, and related services (collectively referred to as the "Platform"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree, you may not access or use the Platform.</p>
          <h4>Eligibility:</h4>
          <ul>
            <li>ou must be at least 13 years old (or the minimum age required in your jurisdiction) to use the Platform.</li>
            <li>By using the Platform, you confirm that the information you provide is accurate and complete.</li>
          </ul>
          <h4>Account Registration and Security:</h4>
          <ul>
            <li>You must create an account to access certain features of the Platform.</li>
            <li>You are responsible for safeguarding your account credentials and agree not to share them with others.</li>
            <li>You must notify us immediately if you suspect unauthorized access to your account.</li>
          </ul>
          <h4>User Content:</h4>
          <ul>
            <li>Ownership: You retain ownership of the photos, videos, and other materials ("User Content") that you upload to the Platform.</li>
            <li>License: By uploading User Content, you grant shareclix.app a worldwide, non-exclusive, royalty-free license to use, store, display, and distribute your content for purposes of operating and improving the Platform.</li>
            <li>Responsibility: You are solely responsible for the legality and appropriateness of your User Content.</li>
            <li>Restrictions: You may not upload content that:</li>
          </ul>
          <p>Violates copyright, trademark, or other intellectual property rights. <br />
            Contains explicit, violent, or otherwise objectionable material.<br />
            Harms others, promotes hate speech, or violates applicable laws.</p>
          <h4>Prohibited Activities:</h4>
          <p>When using the Platform, you agree not to:</p>
          <p>Violate laws or regulations. <br />
            Post false, misleading, or harmful content. <br />
            Engage in activities that disrupt the Platform or its users. <br />
            Use bots, scrapers, or other automated tools without our consent.</p>
          <h4>Privacy:</h4>
          <p>Your use of the Platform is governed by our Privacy Policy, which describes how we collect, use, and share your personal information. By using the Platform, you consent to our data practices.</p>
          <h4>Intellectual Property:</h4>
          <ul>
            <li>All content on the Platform, excluding User Content, is the property of shareclix.app or its licensors.</li>
            <li>You may not use, copy, or distribute our intellectual property without prior written consent.</li>
          </ul>
          <h4>Termination:</h4>

          <ul>
            <li>We may suspend or terminate your account if you violate these Terms or engage in conduct that harms the Platform or its users.</li>
            <li>You may terminate your account at any time. Upon termination, your access to the Platform will end, but some data may be retained as required by law.</li>
          </ul>
          <h4>Disclaimers:</h4>
          <ul>
            <li>The Platform is provided "as is" without any warranties, express or implied.</li>
            <li>We do not guarantee the availability, accuracy, or security of the Platform.</li>
          </ul>
          <h4>Limitation of Liability:</h4>
          <ul>
            <li>To the fullest extent permitted by law, shareclix.app is not liable for any damages, including loss of data, revenue, or profits, arising from your use of the Platform.</li>
            <li>Your sole remedy for dissatisfaction with the Platform is to discontinue its use.</li>
          </ul>
          <h4>Governing Law and Dispute Resolution:</h4>
          <ul>
            <li>These Terms are governed by the laws of India.</li>
            <li>Any disputes arising under these Terms will be resolved in the courts of Jurisdiction.</li>
          </ul>
          <h4>Changes to These Terms:</h4>
          <p>We reserve the right to modify these Terms at any time. Material changes will be communicated to you, and your continued use of the Platform constitutes acceptance of the updated Terms.</p>
          <h4>Contact Us:</h4>
          <p>If you have questions about these Terms, please contact us: <br />
            Email: prakash@sanchaninfo.com<br />
            Address: Flat No 101, Sri RK Homes, Marripalem VUDA Layout, Visakhapatnam, India - 530016.</p>





        </div>

      </div>
      <Footer />
    </div>
  );
};

export default Terms;
