import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ComingSoon from './ComingSoon';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Loader from './Loader'
import Footer from './Footer';
let { appname, lambda } = window.app;
const Shared = () => {
  const [image, setImg] = useState('');
  const [privateList, setPrivateList] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [searchStatus, setStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [showDocAlert, setShowDocAlert] = useState(false);
  const [showSharedDocAlert, setShowSharedDocAlert] = useState(false);
  const [deleteItem, setDetleteItem] = useState(null);
  const [activeLoad, setActiveLoad] = useState(true);
  const [deleteError, setDeleteError] = useState(false);
  useEffect(() => {
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };
    checkSiteObject();
    shareData();
  }, []);

  const shareData = async () => {
    setActiveLoad(true);
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/sharesList?appname=${appname}&clientid=${clientid}`);
      setPrivateList(response.data.result.reverse());
    } catch (error) {
      console.error('Error fetching album info:', error);
    }
  };

  const shareUpdate = async (codeValue, status) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.post(`${lambda}/shareLinkUpdate?appname=${appname}&clientid=${clientid}&code=${codeValue}&status=${status}`);
    
      shareData();
    } catch (error) {
      console.error('Error updating album info:', error);
    }
  };

  const searchShareData = async (e) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/sharesList?appname=${appname}&clientid=${clientid}&status=${searchStatus}&albumName=${albumName}`);
      setPrivateList(response.data.result.reverse());
    } catch (error) {
      console.error('Error fetching album info:', error);
    }
  };

  const handleSharedDeletePopup = (e) => {

    if (selectedRows && selectedRows?.length > 0) {
      setShowSharedDocAlert(true)
    } else {
      setDeleteError(true)
      setTimeout(() => {
        setDeleteError(false)

      }, 2000);
    }

  };

  const shareDataDelete = async (e) => {

    let formData = {
      shareid: []
    }
    if (selectedRows && selectedRows?.length > 0) {
      selectedRows.forEach(item => {
        formData.shareid.push(item.shareid)
      })
      setShowSharedDocAlert(false)
      setActiveLoad(true);
    } else {
      return
    }

    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${lambda}/sharesList?appname=${appname}&clientid=${clientid}`, { data: formData });
      
      shareData();
      handleClearRows()
      setActiveLoad(false);
    } catch (error) {
      setActiveLoad(false);
      console.error('Error updating album info:', error);
    }
  };

  const handleDelete = async () => {
    setShowDocAlert(false)
    setActiveLoad(true);
    let item = { ...deleteItem }
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${lambda}/sharesList?appname=${appname}&clientid=${clientid}`, { data: { shareid: [item.shareid] } });
     
      shareData();
      handleClearRows()
      setActiveLoad(false);
      setDetleteItem(null)
    } catch (error) {
      setActiveLoad(false);
      setDetleteItem(null)
      console.error('Error updating album info:', error);
    }
  }

  const handleDeletePopup = (item) => {
    setShowDocAlert(true)
    setDetleteItem(item)
  };

  useEffect(() => {
    console.log(privateList);
    setActiveLoad(false);
  }, [privateList]);


  const columns = [
    {
      name: 'Shared With',
      selector: row => row?.name,
      width: '10%',
    },
    {
      name: 'Album',
      selector: row => row?.albumName,
      width: '15%',
    },
    {
      name: 'Code',
      selector: row => row?.code,
      width: '5%',
    },
    {
      name: 'Device',
      selector: row => row?.appType,
      width: '8%',
    },
    {
      name: 'Location',
      selector: row => row?.location,
      width: '17%',
    },
    {
      name: 'Status',
      selector: row => row?.status == "Approved" ? "Approved" : row?.status == "Reject" ? "Rejected" : "Pending",
      width: '10%',
    },
    {
      name: 'Created',
      selector: row => moment(row?.created).format('MM-DD-YY'),
      width: '10%',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button
            type="button"
            className={row?.status != "Approved" ? "gen-close like " : "gen-close like disabled" }
            onClick={() => shareUpdate(row?.code, 'Approved')}
          >
            <span className="material-symbols-outlined">thumb_up</span>
          </button>
          <button
            type="button"
            className={row?.status != "Reject" ? "gen-close ms-2 dislike " : "gen-close ms-2 dislike disabled"}
            onClick={() => shareUpdate(row?.code, 'Reject')}
          >
            <span className="material-symbols-outlined">thumb_down</span>
          </button>
          <button
            type="button"
            className="gen-close ms-2"
            onClick={() => handleDeletePopup(row)}
          >
            <span className="material-symbols-outlined"> delete </span>
          </button>
        </>

      ),
      width: '25%',
    },
  ];

 

const noRecordsFound = () => (
 <div className="body-content d-flex justify-content-between">
   
      <div className="text-center transform-center">

        <img src={`${image}images/app/images/default-img.png`} alt="comingsoon" />

        <h3 >
          NO RECORDS FOUND
        </h3>
      </div>
    
  </div>
);

  const myNewTheme = {
    rows: {
      fontSize: '25px'
    }
  }
  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log('Selected Rows: ', selectedRows);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  return (
    <div>
      <div id="page-top">
        <Navbar />
        <div className="inner-body shared_page">
          <div className="inner-wrapper">
            <Sidebar />
            <div className="right-section">
              {activeLoad && <Loader />}
              <div className="page-title">

                <h1 className="sm-heading lite-text">Shared</h1>

              </div>
              <div className="content-block">
                <div className="body-content">
                  
                  <div className='search_block'>
                    <button className={selectedRows && selectedRows?.length > 0 ? "btn gradietn_btn rounded-pill" : "btn gradietn_btn rounded-pill disabled"} onClick={(e) => handleSharedDeletePopup(e)}>Delete<span className="material-symbols-outlined ms-2">delete</span></button>
                    {deleteError && <p className='text-danger'>Please select rows</p>}

                    <div className="right">
                      
                      <select className="form-select" placeholder="Select" name="sortby" onChange={(e) => setStatus(e.target.value)} value={searchStatus} >
                        <option value="">Select</option>
                        <option value="Approved"> Approved </option>
                        <option value="Reject"> Rejected </option>
                        <option value="Pending"> Pending </option>
                      </select>
                      <div className="position-relative">
                        <input type="name" onChange={(e) => setAlbumName(e.target.value)} className="form-control" placeholder="Event Name" value={albumName} />
                        <button className="fill_btn" onClick={(e) => searchShareData(e)}><span className="material-symbols-outlined search-icon">search</span></button>
                      </div>
                       <button onClick={(e) => { shareData(e); setStatus(""); setAlbumName(""); handleClearRows(); setSelectedRows(false) }} className="btn reset_btn">Reset<span className="material-symbols-outlined ms-2">sync</span></button>
                    </div>
                  </div>

                  <div className="div-table">
                  {privateList && privateList.length > 0 ? <DataTable
                      columns={columns}
                      data={privateList}
                      // noDataComponent={!activeLoad && noRecordsFound()}
                      pagination
                      className="transaction-history-table"
                      customTheme={myNewTheme}
                      selectableRows
                      clearSelectedRows={toggledClearRows}
                      onSelectedRowsChange={handleChange}
                    />: noRecordsFound()
                  
                  }</div>
                 

                  <Modal className="modal fade large_popup delete_popup" show={showDocAlert}>
                    <div className="modal-body">
                      <button className="close-btn"><span className="material-icons" onClick={e => setShowDocAlert(false)}>close</span></button>
                      <span className="material-icons delete-icon">delete_outline</span>
                      <h3>Delete</h3>
                      <p>This action cannot be undone.</p>
                      <p>Are you sure you want to delete?</p>
                      <div className="popup-footer">
                        <button className="fill_btn" onClick={(e) => handleDelete(e)}> Yes, Delete </button>
                      </div>
                    </div>
                  </Modal>

                  <Modal className="modal fade large_popup delete_popup" show={showSharedDocAlert}>
                    <div className="modal-body">
                      <button className="close-btn"><span className="material-icons" onClick={e => setShowSharedDocAlert(false)}>close</span></button>
                      <span className="material-icons delete-icon">delete_outline</span>
                      <h3>Delete</h3>
                      <p>This action cannot be undone.</p>
                      <p>Are you sure you want to delete?</p>
                      <div className="popup-footer">
                        <button className="fill_btn" onClick={(e) => shareDataDelete(e)}> Yes, Delete </button>
                      </div>
                    </div>
                  </Modal>

                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shared;
