import React, { useState, useEffect, useCallback } from 'react';
import { FixedSizeList as List, FixedSizeGrid as Grid } from 'react-window';
import axios from 'axios';

let { appname, lambda } = window.app;



const InfiniteScroll = () => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemHeight = 50; // Adjust item height to your needs

    const numColumns = 5; // Number of columns
    const cellWidth = 150; // Width of each cell
    const cellHeight = 150; // Height of each cell
    //   const fetchclientContentInfo = async () => {

    //     try {
    //       const clientid = localStorage.getItem('clientid');
    //       const token = localStorage.getItem('token');

    //       const response = await axios.get(`${lambda}/clientContentInfo`, {
    //         params: {
    //           appname: 'sanchaneventsDev',
    //           clientid,
    //           pageNumber: 1,
    //           assetcount: 25,
    //         }
    //       });

    //       setActiveLoad(false)
    //       setImages(response.data.result);
    //       setUserData(response.data.result)
    //     } catch (error) {
    //       console.error('Error fetching Images:', error);
    //     }
    //   };

    // Fetch data from API
    const fetchMoreData = useCallback(async () => {
        console.log('page ', page)
        setLoading(true);
        try {
            //   const response = await axios.get(`https://api.example.com/data?page=${page}`);
            //   let response = ['hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello']  
            const clientid = localStorage.getItem('clientid');
            const token = localStorage.getItem('token');

            const response = await axios.get(`${lambda}/clientContentInfo`, {
                params: {
                    appname: 'sanchaneventsDev',
                    clientid,
                    pageNumber: page,
                    assetcount: 25,
                }
            });

            if (page == 0) {
                setPage(prevPage => prevPage + 1);
                setLoading(false);
            } else if (response.data.result?.length > 0) {
                setItems(prevItems => [...prevItems, ...response.data.result]);
                // console.log(prevPage , page)
                setPage(prevPage => prevPage + 1);

                //setPage(page + 1)
                setLoading(true);
            } else if (response.data.result?.length == 0) {
                setLoading(false);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }

    }, [page]);

    // Trigger data fetch when component mounts or when page changes
    useEffect(() => {
        fetchMoreData();
    }, [fetchMoreData]);

    // Load more data when the user scrolls to the end
    const handleScroll = ({ scrollOffset, scrollHeight }) => {
        const isBottom = scrollHeight - scrollOffset < itemHeight * 2;
        if (isBottom && !loading) {
            fetchMoreData();
        }
    };

    const numRows = Math.ceil(items.length / numColumns);

    console.log('items ', items)
    return (
        <>
            {/* <div style={{ height: '100vh', overflow: 'auto' }}>
             <List
                height={500}
                itemCount={items.length}
                itemSize={itemHeight}
                width="100%"
                onScroll={handleScroll}
            >
                {({ index, style }) => (
                    <div style={style} className="list-item">

                        <img src={`https://d23f5g3ljiuomd.cloudfront.net/` + items[index].filepath + `?width=150`} />
                    </div>
                )}
            </List>
            {loading && <p>Loading...</p>} 
</div>*/}
            < div className="App" >
                <h1>Image Grid with react-window</h1>
                <Grid
                    columnCount={numColumns}
                    columnWidth={cellWidth}
                    height={740} // Adjust height as needed
                    rowCount={numRows}
                    rowHeight={cellHeight}
                    width={1240} // Adjust width as needed
                >
                    {({ columnIndex, rowIndex, style }) => (
                        <div style={style} className="cell">

                            {items[rowIndex * numColumns + columnIndex]?.filepath && <img src={`https://d23f5g3ljiuomd.cloudfront.net/` + items[rowIndex * numColumns + columnIndex].filepath + `?width=150`} className="image" />}
                        </div>
                    )}


                </Grid>
            </div>
        </>
    );
};

export default InfiniteScroll;
