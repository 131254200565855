
import React, {  } from "react";

import { useNavigate } from "react-router";


import Modal from 'react-bootstrap/Modal';


const SessionPopup = () => {

 const navigate = useNavigate();
   

    const onConfirm = () => {
        localStorage.removeItem("token");
            localStorage.removeItem("currentSessionClientTime");
            localStorage.removeItem("clientid");
            navigate('/signin')
       
    }



    return (
        
        <>

            <Modal className="modal fade large_popup delete_popup access-denied" show={true} >

                    <div className="modal-body">
                        <button className="close-btn" onClick={e => onConfirm()}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">lock_clock</span>
                        <h3>Session Terminated</h3>
                        <p>The current session has been terminated because some one has logged in another window.</p>
                        <div className="popup-footer">
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>OK</button>
                        </div>

                    </div>

            </Modal>


        </>
    )
}
export default SessionPopup