import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from './Footer';
let { appname, lambda } = window.app;

const Home = () => {
    const navigate = useNavigate();
    const [image, setImg] = useState('');
    const [svgImage, setSvgImg] = useState('')
    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);
                setSvgImg(window.site.common.resourcesCloudfront);
                console.log("Timing----->", window.site)
            } else {
                setTimeout(checkSiteObject, 50); // Check again after a short delay
            }
        };

        checkSiteObject();
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, []);

    const handlemenu = (menu) => {
        navigate(menu);
    };
    const d = new Date();
    let year = d.getFullYear();
    return (
        <>
            <div>
                <header className="header-style-two header--sticky">
                    <div className="header-wrapper-main">
                        <a href="#" className="logo">
                            <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/Logo1.png" alt="logo" />
                        </a>

                        <div className="header-right">
                            <div className="nav-area-center">
                                <nav className="navigation">
                                    <ul className="parent-ul">
                                        <li className="has-dropdown with-megamenu">
                                            <Link className="nav-link " to="/about">About</Link>

                                        </li>
                                        <li className="has-dropdown">
                                            <Link className="nav-link" to="/price">Pricing</Link>

                                        </li>
                                        <li className="has-dropdown">
                                            <Link className="nav-link " to="/signin">Sign In</Link>

                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <Link className="rts-btn btn-border" to="/fileupload">
                                Get Started <span class="material-icons icon"> east </span>
                            </Link>

                            <div className="action-area">
                                <div id="search" className="search">
                                    <i className="fa-light fa-magnifying-glass"></i>
                                </div>
                                <div className="menu-icon" id="menu-btn">
                                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0 1.34375C0 0.886719 0.351562 0.5 0.84375 0.5H14.9062C15.3633 0.5 15.75 0.886719 15.75 1.34375C15.75 1.83594 15.3633 2.1875 14.9062 2.1875H0.84375C0.351562 2.1875 0 1.83594 0 1.34375ZM0 6.96875C0 6.51172 0.351562 6.125 0.84375 6.125H10.4062C10.8633 6.125 11.25 6.51172 11.25 6.96875C11.25 7.46094 10.8633 7.8125 10.4062 7.8125H0.84375C0.351562 7.8125 0 7.46094 0 6.96875ZM5.90625 13.4375H0.84375C0.351562 13.4375 0 13.0859 0 12.5938C0 12.1367 0.351562 11.75 0.84375 11.75H5.90625C6.36328 11.75 6.75 12.1367 6.75 12.5938C6.75 13.0859 6.36328 13.4375 5.90625 13.4375Z"
                                            fill="#30373E" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="swiper-banner-two-wrapper ">
                    <div className="swiper mySwiper-banner-two">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">

                                <div className="banner-twoa-rea-start bg_image banner-bg-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">

                                                <div className="banner-inner-content-wrapper animated">
                                                    <span className="pre-title ">Share Clix </span>
                                                    <h1 className="title ">
                                                        Your memories, <span> <br /> your control.</span>

                                                    </h1>
                                                    <p className="disc">
                                                        Create, organize, and share your event photos effortlessly.
                                                    </p>
                                                    <Link to="/fileupload" className="rts-btn btn-border">Create album  <span class="material-icons icon"> east </span></Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className="thumbs-swiper-main-wrapper-two">

                            <div className="swiper swiper-banner-thumb-2">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="rts-about-area rts-section-gap bg-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail-image">
                                    <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/003.png" alt="about" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner-two-wrapper animated">
                                    <div className="title-left-style-two">
                                        <div className="pre-title">
                                            <span className="pre-title">About US</span>
                                            <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/02.png" alt="service" />
                                        </div>

                                        <h2 className="title quote">
                                            Welcome to Share Clix
                                        </h2>
                                        <h6 className="title">Share Your Special Moments Effortlessly!</h6>
                                    </div>
                                    <p className="disc">
                                        Capture the joy of your special moments and share them with the ones who matter most!
                                        Whether it’s a Birthday party, Engagement, Wedding, Family trip, or photoshoot, our platform
                                        allows you to easily upload, organize, and share your memories through a personalized
                                        webpage or even on your Smart TV.
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rts-service-area rts-section-gap">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">

                                <div className="title-area-multiple-elements">
                                    <div className="title-left-style-two">
                                        <div className="pre-title">
                                            <span className="pre-title">Services</span>
                                            <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/02.png" alt="service" />
                                        </div>
                                        <h2 className="title quote">
                                            How it works
                                        </h2>
                                    </div>

                                    <a href="#" className="view-servce-btn">How it works <span className="material-icons icon">
                                        arrow_forward_ios
                                    </span></a>
                                </div>

                            </div>
                        </div>
                        <div className="row g-5 mt--30 rts-slide-up animated">
                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className="singe-serice-style-two">
                                    <Link to="/fileupload">
                                        <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/03.jpg" alt="service" />
                                    </Link>
                                    <div className="body">
                                        <div className="wrapper">
                                            <div className="icon-area">
                                                <div className="icon">
                                                    <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/uploadphotos.svg" />
                                                </div>
                                                <span>01</span>
                                            </div>
                                            <div className="inner">
                                                <Link to="/fileupload">
                                                    <h6 className="title">Upload Your Photos</h6>
                                                </Link>
                                                <p className="disc">Choose from your device or cloud storage and upload photos from your
                                                    latest events.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className="singe-serice-style-two">
                                    <Link to="/fileupload">
                                        <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/04.jpg" alt="service" />
                                    </Link>
                                    <div className="body">
                                        <div className="wrapper">
                                            <div className="icon-area">
                                                <div className="icon">
                                                    <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/createalbum.svg" />
                                                </div>
                                                <span>02</span>
                                            </div>
                                            <div className="inner">
                                                <Link to="/fileupload">
                                                    <h6 className="title">Create Your Album</h6>
                                                </Link>
                                                <p className="disc">Organize your images into beautiful, customized albums with captions
                                                    and descriptions to enhance your story.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 slider">

                                <div className="singe-serice-style-two">
                                    <Link to="/fileupload">
                                        <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/05.jpg" alt="service" />
                                    </Link>
                                    <div className="body">
                                        <div className="wrapper">
                                            <div className="icon-area">
                                                <div className="icon">
                                                    <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/Sharejoy.svg" />
                                                </div>
                                                <span>03</span>
                                            </div>
                                            <div className="inner">
                                                <Link to="/fileupload">
                                                    <h6 className="title">Share the Joy</h6>
                                                </Link>
                                                <p className="disc">Share your album with friends and family through a private link or
                                                    enjoy it on your Smart TV app for an immersive viewing experience.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="rts-section-gap why-chooseus-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 pr--120 pr_sm--0">

                                <div className="why-choose-us-left-two animated">
                                    <div className="title-left-style-two">

                                        <h2 className="title quote">

                                            Why choose us
                                        </h2>
                                    </div>
                                    <p className="disc">
                                        Choosing interior design services offers numerous benefits. Here are a few reasons why you
                                        should consider working with professional interior designers:
                                    </p>
                                </div>

                                <div className="row mt--20 g-5">
                                    <div className="col-lg-6 rts-slide-up">

                                        <div className="single-choose-style-two">
                                            <div className="icon">
                                                <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/user-friendly.png" alt="choose_icon" />
                                            </div>
                                            <div className="right-content">
                                                <h6 className="title">User-Friendly Experience</h6>
                                                <p className="disc">
                                                    Uploading, organizing, and sharing your event photos has never been easier! Our
                                                    intuitive interface is designed for all users, regardless of tech-savviness.
                                                    With just a few clicks, your memories are ready to share.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 rts-slide-up">

                                        <div className="single-choose-style-two">
                                            <div className="icon">
                                                <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/02-1.svg" alt="choose_icon" />
                                            </div>
                                            <div className="right-content">
                                                <h6 className="title">Seamless Sharing Options</h6>
                                                <p className="disc">
                                                    Create beautiful albums and share them through custom web pages or via our Smart
                                                    TV app for an immersive experience. Let your loved ones view your events with
                                                    the same excitement that you experienced.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 rts-slide-up">

                                        <div className="single-choose-style-two">
                                            <div className="icon">
                                                <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/Secure.svg" alt="choose_icon" />
                                            </div>
                                            <div className="right-content">
                                                <h6 className="title">Security and Privacy First</h6>
                                                <p className="disc">
                                                    We know how important your privacy is. That’s why we’ve built our platform with
                                                    top-tier encryption and secure servers to ensure your memories are safe and only
                                                    accessible to those you choose to share with. Your photos are never shared or
                                                    used without your consent.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 rts-slide-up">

                                        <div className="single-choose-style-two">
                                            <div className="icon">
                                                <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/value.png" alt="choose_icon" />
                                            </div>
                                            <div className="right-content">
                                                <h6 className="title">Value Enhancement
                                                </h6>
                                                <p className="disc">
                                                    Well-designed interiors can us significantly enhance value.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 mt_sm--50">
                                <div className="thumbnail">
                                    <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/06.png" alt="service" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="rts-counterup-area-start rts_jump_counter__animation animated">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="counter-main-wrapper-two counter_animation">

                                    <div className="counter-single counter__anim">
                                        <div className="inner">
                                            <h2 className="title"><span className="counter">22</span>k</h2>
                                            <p>Shares</p>
                                        </div>
                                    </div>

                                    <div className="counter-single counter__anim">
                                        <div className="inner">
                                            <h2 className="title"><span className="counter">106</span>+</h2>
                                            <p>albums</p>
                                        </div>
                                    </div>

                                    <div className="counter-single counter__anim">
                                        <div className="inner">
                                            <h2 className="title"><span className="counter">120</span>+</h2>
                                            <p>Award Winning</p>
                                        </div>
                                    </div>

                                    <div className="counter-single counter__anim">
                                        <div className="inner">
                                            <h2 className="title"><span className="counter">101</span>k</h2>
                                            <p>Year Experience</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="rts-cta-main-wrapper rts-section-gap signup_block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 animated">
                                <div className="title-style-center cta-wrapper-two">
                                    <div className="pre-title-area">
                                        <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/02.png" alt="about" />
                                        <span className="pre-title">Create your first Album</span>
                                    </div>
                                    <h2 className="title quote"><span>Ready to</span> Share <br /> Your <span>Memories?</span></h2>

                                    <p className="disc">
                                        We know how important your privacy is. That’s why we’ve built our platform with top-tier
                                        encryption and secure servers to ensure your memories are safe and only accessible to those
                                        you choose to share with. Your photos are never shared or used without your consent.
                                    </p>
                                    <Link className="rts-btn btn-border " to="/signup">SIGN UP <span class="material-icons icon"> east </span></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {/* <div className="rts-footer-area bg-light social-jumpanimation">

                    <div className="rts-copyright-area-two">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="copy-right-area-inner-two">
                                        <p className="disc">© Shareclix media {year}. All Rights Reserved.</p>
                                        <div className="right">
                                            <ul>
                                                <li><Link to="/terms">Terms & conditions</Link></li>
                                                <li><Link to="/privacy">Privacy policy</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}



export default Home;
