import React, { useState } from 'react';
import InsideHeader from './InsideHeader';
import { useNavigate } from 'react-router-dom';

const SubscriptionPlan = ({ handleSubmit }) => {
    const [storage, setStorage] = useState(100); // Default to 100GB
    const [planType, setPlanType] = useState('monthly'); // Default to monthly
    const [totalAmount, setTotalAmount] = useState(100); // Default amount for 100GB monthly

    const navigate = useNavigate();
    const monthlyCostPer100GB = 100; // INR per 100GB for monthly

    // Handle storage change with slider
    const handleStorageChange = (e) => {
        const storageAmount = Number(e.target.value);
        setStorage(storageAmount);
        calculateAmount(storageAmount, planType);
    };

    const handlePlanTypeChange = (selectedPlan) => {
        setPlanType(selectedPlan);
        calculateAmount(storage, selectedPlan);
    };

    // Calculate the total amount
    const calculateAmount = (storageAmount, plan) => {
        const cost = (storageAmount / 100) * monthlyCostPer100GB;
        if (plan === 'monthly') {
            setTotalAmount(cost);
        } else if (plan === 'yearly') {
            const yearlyCost = cost * 12;
            const discount = yearlyCost * 0.1; // 10% discount
            setTotalAmount(yearlyCost - discount);
        }
    };

    // Handle form submission
    const handleFormSubmit = () => {
        const today = new Date();
        const endDate = new Date();
        console.log("planType",planType)
        if (planType === 'monthly') {
            endDate.setDate(today.getDate() + 30);
        } else {
            endDate.setFullYear(today.getFullYear() + 1);
        }

        const payload = {
            storage,
            planType:planType,
            totalAmount: totalAmount,
            totalStorage: storage * 1073741824,
            startDate: today.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
        };
       navigate("/payment", { state: { totalAmount: totalAmount, payload } });
    };

    return (
        <div id="page-top">
            <InsideHeader flag={true} />
            <div className='right-section subscription'>
                <div className="inner-body">
                    <div className="subscription-plan">
                        <h2>Upgrade your storage</h2>
                        {/* <p>Lorem Ipsum dolor semit</p> */}
                        <div className="billing_type">
                            <p>Select Billing type</p>
                            <div className="select_billing">
                                <button
                                    className={`btn ${planType === 'monthly' ? 'select_btn' : ''}`}
                                    onClick={() => handlePlanTypeChange('monthly')}
                                >
                                    Monthly
                                </button>
                                <button
                                    className={`btn yearly ${planType === 'yearly' ? 'select_btn' : ''}`}
                                    onClick={() => handlePlanTypeChange('yearly')}
                                >
                                    Yearly <span>save 10%</span>
                                </button>
                            </div>
                        </div>
                        <div className="storage_range">
                            <div className="select_storage">
                                <span className="material-symbols-outlined">cloud</span>
                                <p>Select Storage</p>
                            </div>
                            <div className="storage_input">
                                <input
                                    type="range"
                                    min="100"
                                    max="1024"
                                    step="100"
                                    value={storage}
                                    className='form-control'
                                    onChange={handleStorageChange}
                                />
                            </div>
                            <div className="storage_point">
                                <p>100GB</p>
                                <p>500GB</p>
                                <p>1TB</p>
                            </div>
                        </div>
                        <div>
                            <h3 className="total">Total <span>₹{totalAmount}</span></h3>
                        </div>
                        <div className="bottom_bar">
                            <button className="btn gradietn_btn rounded-pill" onClick={handleFormSubmit}>
                                Proceed to Buy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlan;
