import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Compressor from "compressorjs";
import * as glfx from "glfx";
import { getCroppedImg } from "./cropImage"; // Helper function to get cropped image
import InsideHeader from "./InsideHeader";

function ImageEditor() {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [processedImage, setProcessedImage] = useState(null); // To store final processed image
  
  // Handle image upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // Handle crop completion
  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Generate cropped image
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(croppedImg);
      setProcessedImage(croppedImg); // Initially set processed image to the cropped version
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels]);

  // Resize image using compressorjs
  const handleResize = () => {
    fetch(croppedImage)
      .then(res => res.blob())
      .then(blob => {
        new Compressor(blob, {
          quality: 0.8, // Reduce quality for resizing
          success: (compressedResult) => {
            const url = URL.createObjectURL(compressedResult);
            setProcessedImage(url); // Update the image with the compressed result
          },
          error: (err) => {
            console.error("Compression failed:", err);
          }
        });
      })
      .catch(err => {
        console.error("Failed to fetch the image blob:", err);
      });
  };

  // Apply auto-beautify filter using glfx.js
  const handleBeautify = () => {
    const imageElement = new Image();
    imageElement.src = processedImage; // Use processedImage which could be cropped/resized
    imageElement.onload = () => {
      const canvas = glfx.canvas();
      const texture = canvas.texture(imageElement);
      canvas.draw(texture).brightnessContrast(0.1, 0.2).update();
      const beautifiedImage = canvas.toDataURL("image/png");
      setProcessedImage(beautifiedImage); // Update the processed image after beautification
    };
  };

  return (
    <div id="page-top">
      <InsideHeader flag={true} />
      <div className="right-section">
        <div className="inner-body blankpage">
          <div className="text-center transform-center">
            <div className="App">
              <input type="file" accept="image/jpeg,image/png" onChange={handleFileChange} />

              {image && (
                <div>
                  <div style={{ position: "relative", width: "100%", height: 400 }}>
                    <Cropper
                      image={image}
                      crop={crop}
                      zoom={zoom}
                      aspect={4 / 3}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </div>

                  <button onClick={showCroppedImage} style={{ margin: '10px' }}>Crop Image</button>
                </div>
              )}


              {croppedImage && (
                <div>
                  <img src={processedImage} alt="Processed" style={{ maxWidth: "100%", marginTop: '20px' }} />
                  <div style={{ marginTop: "10px" }}>
                    <button onClick={handleResize} style={{ marginRight: '10px' }}>Resize Image</button>
                    <button onClick={handleBeautify}>Auto Beautify</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageEditor;
