import React from 'react'
import { useNavigate } from 'react-router-dom';
import InsideHeader from './InsideHeader';
import Footer from './Footer';
let { appname, lambda } = window.app;
function ComingSoon(props) {
    const navigate = useNavigate();
    const handleBackToHome = () => {
        navigate('/'); // Navigate to the home page
    };
    //   const image ='asdf';
    return (
        <div id="page-top">

            <InsideHeader flag={true} />
           
                <div className='right-section'>
                    <div className="inner-body blankpage">
                        <div className="text-center transform-center">

                            <img src={`${props?.image}images/app/images/default-img.png`} alt="comingsoon" />

                            <h3>
                                COMING SOON !
                            </h3>
                            <p>The Page is Under Construction.</p>
                            {!props?.flag &&
                                <div className="d-flex justify-content-center">
                                    <button className="btn_style" onClick={handleBackToHome}>Back to Home</button>
                                </div>
                            }
                        </div>
                    </div>
              

                    <Footer/>
            </div>
        </div>
    );
}

export default ComingSoon