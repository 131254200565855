import React, { createContext, useState } from 'react';

// Create the context
export const CommonContext = createContext();

// Create the provider component
export const CommonProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [navOpen, setNav] = useState(false);
    return (
        <CommonContext.Provider value={{ userData, setUserData, navOpen, setNav }}>
            {children}
        </CommonContext.Provider>
    );
};
