import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader'
import { CommonContext } from './CommonContext';
let { appname, lambda } = window.app;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [albumStatus, setalbumStatus] = useState('');
  const { userData, setUserData } = useContext(CommonContext);
  const { navOpen, setNav } = useContext(CommonContext);
  const [activeLoad, setActiveLoad] = useState(false);
  const isActive = (path) => location.pathname === path ? 'active' : '';



  useEffect(() => {
    var text = location.pathname;
    var term = "album";

    
    if (text.indexOf(term) != -1)
      setalbumStatus('active')

  }, []);

  useEffect(() => {
    console.log('navOpensss ',navOpen)

  }, [navOpen]);
 

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, [navigate]);

  const fetchUserData = async () => {
    setActiveLoad(true)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);

      if (response.data?.result === "Client not found") {
        localStorage.removeItem("token");
        localStorage.removeItem("currentSessionClientTime");
        localStorage.removeItem("clientid");
        navigate('/signin');
      } else if (response.status === 200 && response.data?.result?.[0]) {
        setUserData(response.data.result[0]);
        setActiveLoad(false)
      } else {
        setActiveLoad(false)
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      setActiveLoad(false)
      console.error('Error fetching user data:', err);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setNav(!navOpen);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const { usedStorage = 0, totalStorage = 0, remainingStorage = 0 } = userData || {};
  let storageUsed = usedStorage > 0 ? usedStorage : 0

  return (
    <div className={navOpen ?"left-nav mobile_nav" : "left-nav"}>
      <ul>
        <li className={isActive('/dashboard')} onClick={() => handleNavigation('/dashboard')}>
          <span className="material-symbols-outlined me-3">space_dashboard</span>
          <span className="md-txt">Dashboard</span>
        </li>
        <li className={isActive('/all-files')} onClick={() => handleNavigation('/all-files')}>
          <span className="material-symbols-outlined me-3">subscriptions</span>
          <span className="md-txt">All Files</span>
        </li>
        <li className={albumStatus} onClick={() => handleNavigation('/albums')}>
          <span className="material-symbols-outlined me-3">layers</span>
          <span className="md-txt">Albums</span>
        </li>
        <li className={isActive('/shared')} onClick={() => handleNavigation('/shared')}>
          <span className="material-symbols-outlined me-3">
            diversity_2
          </span>
          <span className="md-txt">Shared</span>
        </li>
        <li className={isActive('/transaction-history')} onClick={() => handleNavigation('/transaction-history')}>
          <span className="material-symbols-outlined me-3">
            contract
          </span>
          <span className="md-txt">Transactions</span>
        </li>

        <li className={isActive('/mydevices')} onClick={() => handleNavigation('/mydevices')}>
          <span className="material-symbols-outlined me-3">
            devices
          </span>
          <span className="md-txt">My Devices</span>
        </li>

        {/* <li className={isActive('/my-favorites')} onClick={() => handleNavigation('/my-favorites')}>
          <span className="material-symbols-outlined me-3">favorite</span>
          <span className="md-txt">My Favorites</span>
        </li>
        <li className={isActive('/my-clips')} onClick={() => handleNavigation('/my-clips')}>
          <span className="material-symbols-outlined me-3">video_library</span>
          <span className="md-txt">My Clips</span>
        </li> */}
        <li className={isActive('/settings')} onClick={() => handleNavigation('/settings')}>
          <span className="material-symbols-outlined me-3">settings</span>
          <span className="md-txt">Settings</span>
        </li>
      </ul>


      {activeLoad ? <Loader /> :
        <div className="storage-block">
          <div className="storage-header flex-spread">
            <div className="storage-title">
              <span className="material-symbols-outlined me-1">cloud</span>
              <h6>Storage</h6>
            </div>
            {userData && <h6 className="store-percent">{usedStorage > 0 ? ((storageUsed / totalStorage) * 100).toFixed(2) : "0.00"}%</h6>}
          </div>
          <div className="storage-body">
            <div className="storage-progress">
              <div className="storage-bar">
                <div
                  className="storage-progress-bar"
                  style={{
                    width: `${(storageUsed / totalStorage) * 100}%`
                  }}
                ></div>
              </div>
            </div>
            {userData && <p className="storage-used">
              <span className="bold-txt">{formatBytes(storageUsed ?? 0)}</span> of {formatBytes(totalStorage ?? 0)}
            </p>}
          </div>
          <div className="storage-footer">
            <button className="small-btn med-txt" onClick={() => { navigate('/subscription') }}>Upgrade</button>
          </div>
        </div>}


    </div>
  );
};

export default Sidebar;
