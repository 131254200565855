import React, { useState } from 'react'; // Remove lazy loading temporarily
import FilerobotImageEditor from 'react-filerobot-image-editor'; // Directly import without lazy
import InsideHeader from './InsideHeader';

function ImageEditorPage() {
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);

    const openImgEditor = () => {
        setIsImgEditorShown(true);
    };

    const closeImgEditor = () => {
        setIsImgEditorShown(false);
    };

    const handleImageSave = (editedImageObject) => {
        console.log('Saved image:', editedImageObject);
    };

    return (
        <div id="page-top">
            <InsideHeader flag={true} />
            <div className='right-section'>
                <div className="inner-body blankpage">
                    <div className="text-center transform-center">
                        <button onClick={openImgEditor}>Open Filerobot Image Editor</button>
                        {isImgEditorShown && (
                            <FilerobotImageEditor
                                source="https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
                                onSave={handleImageSave}
                                onClose={closeImgEditor}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageEditorPage;
