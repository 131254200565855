import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from react-router-dom
import ComingSoon from './ComingSoon';

const Terms = () => {
  const navigate = useNavigate(); // Hook to handle navigation
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); 
      }
    };

    checkSiteObject();
  }, []);
  const handleBackToHome = () => {
    navigate(-1); // Navigate to the home page
  };

  return (
    <ComingSoon image={image} />
  );
};

export default Terms;
