import React, { useState, useEffect } from 'react';


const RegionNotFound = () => {

    useEffect(() => {
        // localStorage.clear()

    }, []);


    return (
<div id="page-top">
    <div className="inner-body">
      <div >
      <div >
      <div className="content-block blank-page">
      <div className="text-center transform-center">
      <h2>ERROR!</h2>
      <img class="defualt-img" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/default-img-latest1.png" alt="comingsoon"/>
      <p className="mt-4">Sorry! This website is not currently available in your region</p>
      <img class="logo mt-3" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/logo-dark1.png" alt="comingsoon"/>
      </div>
      </div>
      </div>
      </div>
      </div>
    </div>

    )
}



export default RegionNotFound;
