import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactPlayer from 'react-player'
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Loader from './Loader'
import { CommonContext } from './CommonContext';
import SessionPopup from './SessionPopup';
import InfiniteScroll from 'react-infinite-scroll-component';
import Footer from './Footer';
let { appname, lambda } = window.app;
const Content = (props) => {
  const [Images, setImages] = useState(props.videoFiles);
  const [selectedImages, setSelectedImages] = useState([]); // Store selected images
  const [ToggleSwitch, setToggleSwitch] = useState(false);
  const [albumList, setAlbumList] = useState([]);
  const [image, setImg] = useState('');
  const [sourceImg, setSourceImg] = useState('');
  const [showDocAlert, setShowDocAlert] = useState(false);
  const [activeLoad, setActiveLoad] = useState(false);
  const navigate = useNavigate();
  const [cardWidths, setCardWidths] = useState({});
  const [videoPlayer, setVideoPlayer] = useState(false);
  const [playerPath, setplayerPath] = useState(null);
  const [largeImage, setLargeImage] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [imageWidth, setImageWidth] = useState(0);
  const [sortData, setSortData] = useState({});
  const { userData, setUserData } = useContext(CommonContext);
  const [cardWidth1, setCardWidth1] = useState(window.innerHeight * 0.8);
  // const [selectDates, setDates] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef(null);
  const [showSessionPopupup, setShowSessionPopupup] = useState(false);
  const [proxiesCloudFront, setProxiesCloudFront] = useState('');

  const fetchImages = async (pageNumber) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/clientContentInfo`, {
        params: {
          appname: 'sanchaneventsDev',
          clientid,
          pageNumber: pageNumber,
          assetcount: 25
        }
      });
      setIsLoading(false);
      return response.data.result;
    } catch (error) {
      console.error('Error fetching Images:', error);
      setIsLoading(false);
      return [];
    }
  };

  const loadMoreImages = async () => {
    const newImages = await fetchImages(page + 1);
    if (newImages?.length === 0) {
      setHasMore(false);
    } else {
      setImages(prevImages => [...prevImages, ...newImages]);
      setPage(prevPage => prevPage + 1);
    }
  };

  const loadPreviousImages = async () => {
    if (page > 1) {
      const previousImages = await fetchImages(page - 1);
      setImages(prevImages => [...previousImages, ...prevImages]);
      setPage(prevPage => prevPage - 1);
    }
  };

  useEffect(() => {
    const initialLoad = async () => {
      const initialImages = await fetchImages(1);
      setImages(initialImages);
    };
    initialLoad();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current && scrollRef.current.scrollTop === 0 && !isLoading) {
        loadPreviousImages();
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, isLoading]);

  useEffect(() => {
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
        setSourceImg(window.site.common.sourceCloudFront);
        setProxiesCloudFront(window.site.common.proxiesCloudFront);
      } else {
        setTimeout(checkSiteObject, 50);
      }
    };
    checkSiteObject();
  }, []);


  useEffect(() => {
    const handleResize = () => {
      const imageHeight = window.innerHeight * 1; // 80% of 80% (20% reduction)
      setCardWidth1(imageHeight); // Update width based on window size
    };

    window.addEventListener('resize', handleResize);

    // Set initial width on mount
    handleResize();

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setImages(props.videoFiles);
  }, [props.videoFiles]);

  const fetchclientContentInfo = async () => {
    setActiveLoad(true)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      const response = await axios.get(`${lambda}/clientContentInfo`, {
        params: {
          appname: 'sanchaneventsDev',
          clientid,
          pageNumber: 1,
          assetcount: 25,
        }
      });

      setActiveLoad(false)
      setImages(response.data.result);
      setUserData(response.data.result)
    } catch (error) {
      console.error('Error fetching Images:', error);
    }
  };
  const cardWidth = $(".thumb-block").width() ? $(".thumb-block").width() : 160;
  // const cardWidth = 160;

  const fetchAlbumInfo = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setActiveLoad(true)
      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientid}&token=${token}`);

      if (response?.data?.result == "Invalid token or Expired") {
        setShowSessionPopupup(true)
      } else {
        setAlbumList(response.data.result);
        setActiveLoad(false)
      }
    } catch (error) {
      setActiveLoad(false)
      console.error('Error fetching album info:', error);
    }
  };

  const fetchUserData = async () => {

    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}&token=${token}`);
      if (response.data?.result === "Client not found") {
        localStorage.removeItem("token");
        localStorage.removeItem("currentSessionClientTime");
        localStorage.removeItem("clientid");
        navigate('/signin');
      } else if (response.status === 200 && response.data?.result?.[0]) {
        setUserData(response.data.result[0]);

      } else {

        throw new Error('Unexpected response format');
      }
    } catch (err) {

      console.error('Error fetching user data:', err);
    }
  };

  useEffect(() => {
    fetchAlbumInfo();
    fetchclientContentInfo();
  }, []);



  useEffect(() => {
    if (selectedImages.length <= 0) {
      setToggleSwitch(false);
    }
  }, [selectedImages]);

  useEffect(() => {

    if (selectedImages && selectedImages?.length > 0) {
      // setTimeout(() => {
      selectedImages.forEach((item) => {
        // document.getElementById(item.contentid).click();
        document.getElementById(item.contentid).checked = true;
      });
      // }, 2000);

    }
  }, [sortData]);



  const handlePlayVideo = (item) => {

    setVideoPlayer(true)
    let filepath = sourceImg + "/" + item.filepath + item.filename

    if (item?.video?.m3u8?.proxyUrl) {
      filepath = proxiesCloudFront + item?.video?.m3u8?.proxyUrl
    }

    setplayerPath(filepath)
  };

  const handleImage = (item) => {
    setImagePopup(true)
    setLargeImage(item)
  };

  const getNotMatchedObjects = (arr1, arr2, key) => {
    const onlyInArr1 = arr1.filter(item1 => !arr2.some(item2 => item1[key] === item2[key]));
    const onlyInArr2 = arr2.filter(item2 => !arr1.some(item1 => item1[key] === item2[key]));

    return [...onlyInArr1, ...onlyInArr2];
  };

  const handleSelectAll = (data, e) => {
    // console.log(e.target.checked, "eeee", sortData[data])
    if (e.target.checked == true) {
      let imagesSelected = [...selectedImages]
      let finalSelected = imagesSelected.filter(item => item.selectDate == data)
      let notMatched = getNotMatchedObjects(sortData[data], finalSelected, 'contentid');

      if (notMatched && notMatched?.length == 0) {
        notMatched = sortData[data]
      }
      notMatched?.forEach((item) => {
        document.getElementById(item.contentid).click();
      });
    } else {
      let imagesSelected = [...selectedImages]
      let matched = sortData[data].filter(item1 =>
        imagesSelected.some(item2 => item2.contentid === item1.contentid)
      );

      if (matched && matched?.length == 0) {
        matched = sortData[data]
      }
      matched?.forEach((item) => {
        document.getElementById(item.contentid).click();
      });
    }

  }


  const handleSelectImage = (selectedItem, date) => {
    setSelectedImages((prev) => {
      const isSelected = prev.some(image => image.contentid === selectedItem.contentid);

      if (isSelected) {
        // Remove if already selected
        return prev.filter(image => image.contentid !== selectedItem.contentid);
      } else {
        // Add new selected object
        return [...prev, {
          contentid: selectedItem.contentid,
          filepath: selectedItem.filepath,
          filetype: selectedItem.filetype,
          createdOn: selectedItem.createdOn,
          filename: selectedItem.filename,
          selectDate: date
        }];
      }
    });
  };

  const handleDeleteImages = async () => {
    try {
      console.log('Deleting images:', selectedImages);
      setImages((prevImages) => prevImages.filter((image) => !selectedImages.includes(image.contentid)));
      setSelectedImages([]);
    } catch (error) {
      console.error('Error deleting images:', error);
    }
  };

  const handleAddToAlbum = async (albumName) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setActiveLoad(true)
      const response = await axios.post(`${lambda}/addAlbum?appname=${appname}&clientid=${clientid}`, {
        albumName: albumName,
        contentids: selectedImages
      });
      if (response?.data?.result == "Invalid token or Expired") {
        setShowSessionPopupup(true)
      } else {
        console.log('Images added to album:', response.data);
        setActiveLoad(false)
        setSelectedImages([]);
        toast.success(`Images added to ${albumName} successfully!`);
      }
    } catch (error) {
      console.error('Error adding images to album:', error);
      setActiveLoad(false)
      toast.error('Failed to add images to album.');
    }
  };

  //   const handleAddToExistingAlbum = async (albumItem) => {
  //     try {
  //       const albumid = albumItem?.albumid;
  //       const clientid = localStorage.getItem('clientid');
  // const token = localStorage.getItem('token');
  //       setActiveLoad(true)
  //       const response = await axios.post(`${lambda}/addAlbum?appname=${appname}&clientid=${clientid}&albumid=${albumid}`, {
  //         contentid: selectedImages
  //       });
  //       console.log('Images added to album:', response.data);
  //       setActiveLoad(false)
  //       setSelectedImages([]);
  //       toast.success(`Images added to ${albumItem.albumName} successfully!`);
  //     } catch (error) {
  //       setActiveLoad(false)
  //       console.error('Error adding images to album:', error);
  //       toast.error('Failed to add images to album.');
  //     }
  //   };
  const handleDeletePopup = (e) => {
    setShowDocAlert(true)
  };
  // console.log('selected', selectedImages)

  const handleDeleteContent = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setActiveLoad(true);
      setShowDocAlert(false)
      // Extracting contentid from selectedImages
      const payload = {
        contentid: selectedImages.map(image => image.contentid)
      };

      const response = await axios.post(`${lambda}/deleteFiles?appname=${appname}&clientid=${clientid}&from=content`, {
        contentid: selectedImages.map(image => image.contentid)
      });
      if (response?.data?.result == "Invalid token or Expired") {
        setShowSessionPopupup(true)
      } else {
        if (response.status === 200) {
          fetchclientContentInfo();
          setActiveLoad(false);
          setSelectedImages([]);
          toast.success('Content deleted successfully!');
          fetchUserData()

          // Optionally, update the UI or refetch the content list here.
        } else {
          toast.error('Failed to delete the content. Please try again.');
        }
      }
      console.log('response', response);
    } catch (error) {
      setActiveLoad(false);
      console.error('Error deleting content:', error);
      toast.error('An error occurred while deleting the content.');
    }
  };

  useEffect(() => {
    if (Images && Images?.length > 0) {
      let dateFiltered = groupBy(Images, 'createdOn')
      // console.log("dateFiltered", dateFiltered)
      setSortData(dateFiltered)
      fetchUserData();
    }

  }, [Images]);

  useEffect(() => {
    const widths = {};
    document.querySelectorAll(".thumb-img").forEach((img) => {
      const id = img.getAttribute("data-id");
      if (id) {
        widths[id] = img.clientWidth;
      }
    });

    setCardWidths(widths);
  }, [Images]); // Re-run when Images change
  // Calculate the width of the large image when the modal opens
  useEffect(() => {
    if (imagePopup && largeImage) {
      const calculateWidth = () => {
        const img = document.querySelector('.large-img');
        if (img) {
          setImageWidth(img.clientWidth);
        }
      };
      // Run after modal is open
      calculateWidth();

      // Optionally add a resize listener to update width dynamically if needed
      window.addEventListener('resize', calculateWidth);

      // Cleanup the listener when modal closes
      return () => window.removeEventListener('resize', calculateWidth);
    }
  }, [imagePopup, largeImage]);

  const weekOfMonth = (m) => {
    const firstWeekDay = moment(m).startOf('week').format("DD MMM YYYY");
    const lastWeekDay = moment(m).endOf('week').format("DD MMM YYYY");

    return firstWeekDay.toString() + " to " + lastWeekDay.toString()
    // return moment(m).week() - moment(m).startOf('month').week() + 1;
  }

  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      var key = new Date(obj[property]).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const handleSortGroup = (e) => {
    // console.log(e.target.value)

    if (e.target.value == "Week") {
      const groupofweek = Images.reduce((acc, item) => {

        let date = item.createdOn
        let weekOfCurrentMonth = weekOfMonth(date);

        const yearWeekofMonth = `${weekOfCurrentMonth}`;

        if (!acc[yearWeekofMonth]) {
          acc[yearWeekofMonth] = [];
        }
        // push the current date that belongs to the year-week calculated befor
        acc[yearWeekofMonth].push(item);

        return acc;

      }, {});
      setSortData(groupofweek)
    } else if (e.target.value == "Month") {
      const groupsmonth = Images.reduce((acc, item) => {
        let date = item.createdOn
        // create a composed key: 'year-week' 
        const yearWeek = `${moment(date).format("MMM")} ${moment(date).year()} `;
        // console.log("yearWeek ", yearWeek)
        // add this key as a property to the result object
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }

        // push the current date that belongs to the year-week calculated befor
        acc[yearWeek].push(item);

        return acc;

      }, {});
      setSortData(groupsmonth)
    } else {
      // setSortData({})
      let dateFiltered = groupBy(Images, 'createdOn')
      setSortData(dateFiltered)
    }

    setSelectedImages([])


  };

  const handleImageEdit = (contentId, filePath, item) => {
    navigate('/edit/' + contentId, { state: { filePath, item } })
  };


  return (
    <div className="right-section" ref={scrollRef}>
      {showSessionPopupup && <SessionPopup />}
      {activeLoad && <Loader />}
      <div className="page-title">
        <h1 className="sm-heading lite-text">All Files</h1>
        <div className="d-flex align-items-center">
          {/* <button className="btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0 me-3" onClick={() => navigate('/fileupload')}>
            Upload Files<span className="material-symbols-outlined ms-2">add</span>
          </button> */}
          <button className="btn gradietn_btn rounded-pill mr-10" onClick={() => navigate('/fileupload')}>
            Upload
          </button>
          <label className="form-label">Sort By</label>
          <select className="form-select" placeholder="Select" name="sortby" onChange={(e) => handleSortGroup(e)} >
            <option value="">All</option>
            <option value="Week">Week</option>
            <option value="Month">Month</option>
          </select>
        </div>
      </div>
      {!activeLoad && Images?.length > 0 && <>
        <InfiniteScroll
          dataLength={Images.length}
          next={loadMoreImages}
          hasMore={hasMore}
          // loader={<h4>Loading...</h4>}

          scrollableTarget="right-section"
        >
          <div className="content-block">


            {sortData && Object.keys(sortData).length > 0 &&
              Object.keys(sortData).map((sitem, si) => (
                <>
                  <h3 className="sm-heading bold-txt lite-text">{sitem} &nbsp;&nbsp;
                    <span className='handleSelectAll'><input type="checkbox" id={sitem} onClick={(e) => handleSelectAll(sitem, e)} /></span>
                  </h3>
                  <div key={si} className='fusion-row'>


                    {sortData[sitem]?.length > 0 && sortData[sitem]?.map((item) => (
                      <div className="column8 g-3" key={item.contentid}  >
                        <div className={`thumb-block ${selectedImages.some(image => image.contentid === item.contentid) ? 'selected' : ''}`}>
                          <p className="file_name">{item?.filename}</p>
                          <div className="dummy-thumb" onClick={() => item?.filetype?.startsWith('video/') ? handlePlayVideo(item) : handleImage(`${image}${item.filepath}`)}></div>
                          <div className="check-block">
                            <label className="check-container">
                              <input
                                type="checkbox"
                                id={item.contentid}
                                onClick={() => handleSelectImage(item, sitem)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>

                          {item?.filetype?.startsWith('video/') ? (<>
                            <div className="thumbactions" onClick={() => handlePlayVideo(item)}>
                              <span className="material-symbols-outlined"> play_arrow</span>
                            </div>
                            <img
                              // src="/assets/img/playdefault.jpg"
                              src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/play-default.jpg"
                              className="thumb-img"
                              alt="thumbnail"
                              data-id={item.contentid}

                            /></>
                          ) : (
                            <><div className="thumbactions">
                              <span className="material-symbols-outlined" title="View" onClick={() => handleImage(`${image}${item.filepath}`)}>
                                expand_content
                              </span>
                              <span className="material-icons" title=" Edit " onClick={(e) => handleImageEdit(item.contentid, image + item.filepath, item)}>tune</span>
                            </div>
                              <img
                                src={`${image}${item.filepath}?auto=compress,format&width=${cardWidth}`}
                                className="thumb-img"
                                alt="thumbnail"
                                data-id={item.contentid}
                              />
                            </>
                          )}


                        </div>
                      </div>
                    ))}
                  </div></>))

            }



          </div>
        </InfiniteScroll>
      </>
      }
      {!activeLoad && Images?.length <= 0 && <>

        <div className="inner-body blankpage">
          <div className="text-center transform-center">

            <img src={`${image}images/app/images/default-img.png`} alt="comingsoon" />

            <h3>
              NO CONTENT FOUND
            </h3>

          </div>
        </div>

      </>
      }

      <Modal className="modal fade video-popup" show={videoPlayer}>
        <div className="modal-body">
          <button className="close-btn"><span className="material-icons" onClick={e => setVideoPlayer(false)}>close</span></button>
          <div className='player-wrapper'>
            <ReactPlayer url={playerPath} playing controls />
          </div>  </div>
      </Modal>



      <Modal className="modal fade image-popup" show={imagePopup} onHide={() => setImagePopup(false)}>
        <div className="modal-body">
          <button className="close-btn">
            <span className="material-icons" onClick={() => setImagePopup(false)}>close</span>
          </button>
          <img
            src={`${largeImage}?auto=compress,format&height=${Math.round(cardWidth1)}`} // Set width in src
            alt="Large"
            className="large-img"

          />
        </div>
      </Modal>
      <Modal className="modal fade large_popup delete_popup" show={showDocAlert}>
        <div className="modal-body">
          <button className="close-btn"><span className="material-icons" onClick={e => setShowDocAlert(false)}>close</span></button>
          <span className="material-icons delete-icon">delete_outline</span>
          <h3>Delete</h3>
          <p>This action cannot be undone.</p>
          <p>Are you sure you want to delete {selectedImages?.length > 1 ? "Files" : "File"}?</p>
          <div className="popup-footer">
            <button className="fill_btn" onClick={(e) => handleDeleteContent()}> Yes, Delete {selectedImages?.length > 1 ? "Them" : "It"}</button>
          </div>
        </div>
      </Modal>

      {selectedImages?.length > 0 && (
        <div className="content-footer show">

          <button className="btn_outline px-3 py-2 mb-lg-0 me-2" onClick={(e) => handleDeletePopup(e)}>
            Delete<span className="material-symbols-outlined icon ms-2">delete</span>
          </button>
          <div className="dropup">
            <button
              className={`btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0 ${ToggleSwitch ? 'show' : ''}`}
              // onClick={() => setToggleSwitch(!ToggleSwitch)}
              onClick={() => { navigate('/event', { state: { selectedImages } }) }}
            >
              Manage<span className="material-symbols-outlined icon ms-2">folder_managed</span>
            </button>

          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Content;