import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Content from './Content';
// import './App.css'; // Add custom styles if needed
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import Swal from 'sweetalert2';
// import SweetAlert from 'react-bootstrap-sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Uploader } from "../utils/upload"
let { appname, lambda } = window.app;

//import { useHistory, Link } from "react-router-dom";
const MainContent = () => {
  // const [isSigningOut, setIsSigningOut] = useState(false);
  const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const location = useLocation();
// const history = useHistory();
  const [files, setFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [tags, setTags] = useState('');
  const [pgvalue, setPgvalue] = useState(undefined)
  const [perf, setPerf] = useState(undefined)
  const [baseUrl, setBaseUrl] = useState("https://30rewddepd.execute-api.us-east-1.amazonaws.com/prod/")
  const [partsize, setPartsize] = useState(100)
  const [numuploads, setNumuploads] = useState(3)
  const [ta, setTa] = useState(undefined)
  const [closeClick, setCloseClick] = useState(false)

  useEffect(() => {
    if (location.state) {
      setVideoFiles(location.state.videoFiles);
      setTotalSize(location.state.totalSize);
      setFiles(location.state.files);
      setTags(location.state.tags);
    }

  }, [location.state]);
  // console.log('location.state', location.state)

  useEffect(() => {
    if (files?.length > 0 && totalSize) {
      console.log("files", files)
      handleUploadfunction(files, totalSize)

    }

  }, [totalSize, files]);
  useEffect(() => {
    //    console.log('videoFiles updated in Content:', videoFiles);
  }, [videoFiles]);
  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = () => {
        reject(new Error('Error loading video metadata'));
      };

      video.src = URL.createObjectURL(file);
    });
  };
  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const createProgressToast = (fileName) => {
    let progressToastId = toast(`Uploading ${fileName}...`, {
      progress: 0,
      closeOnClick: false,
      autoClose: false,

    });

    return progressToastId;
  };

  // Function to update progress toast
  const updateProgressToast = (toastId, progress) => {
    toast.update(toastId, {
      progress: progress / 100,
    });
  };

  // Function to close and show success or error message
  const finalizeToast = (toastId, fileName, success = true) => {
    toast.update(toastId, {
      render: success ? `${fileName} uploaded successfully!` : `${fileName} failed to upload.`,
      type: success ? 'success' : 'error',  // Using 'success' and 'error' as strings
      autoClose: 2000,
      closeOnClick: true,
    });

  };
 

  const handleUploadfunction = async (files, totalSize) => {
    if (!files || files.length === 0) {
      Swal.fire({
        title: 'No Files Selected',
        text: 'Please select a file to upload.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }

    const uploadPromises = Array.from(files).map(async (file) => {
      const timestamp = Date.now();
      const fileExtension = file?.name?.split('.').pop();
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const ObjectID = require("bson-objectid");

      const uniqueId = new ObjectID().toString();
      let uploadFilePath;
      let bucket;

      // Check the file type
      if (file?.type?.startsWith('video/')) {
        // File is a video
        // uploadFilePath = `${clientid}/files/${uniqueId}_video_${timestamp}.${fileExtension}`;
        bucket = window.site && window.site.common && window.site.common.sourceBucket; // Use source bucket for videos
        // videoUploadFunction(uploadFilePath,bucket)
        const timestamp = Date.now();
        const fileExtension = file?.name?.split('.').pop();
        const clientid = localStorage.getItem('clientid');

        const ObjectID = require("bson-objectid");
        const uniqueId = new ObjectID().toString();
        const uploadFilePath = `${clientid}/files/${uniqueId}/`;
        const fileNameUnique = `${uniqueId}_video_${timestamp}.${fileExtension}`;

        // Track upload progress
        const uploadPromise = getVideoDuration(file).then(async (duration) => {
          const formattedDuration = formatDuration(duration);

          const uploaderOptions = {
            file: file,
            baseURL: baseUrl,
            chunkSize: partsize,
            threadsQuantity: numuploads,
            useTransferAcceleration: ta,
            bucket: uploadFilePath,
            fileNameUnique: fileNameUnique,
            sourceBucket: bucket
          };

          let percentage = undefined;
          setPgvalue(0);
          setPerf("-");
          const uploader = new Uploader(uploaderOptions);
          const tBegin = performance.now();
          console.log('tBegin', tBegin);

          const progressToastId = createProgressToast(file.name);

          uploader
            .onProgress(({ percentage: newPercentage }) => {
              // Avoid logging the same percentage twice
              if (newPercentage !== percentage) {
                percentage = newPercentage;
                setPgvalue(percentage);
                updateProgressToast(progressToastId, percentage);

                if (percentage === 100) {
                  setPerf((performance.now() - tBegin) / 1000);

                  
                  // const pushtoElementalPayload = {
                  //   "params": {
                  //     "Destination": window?.site?.common?.proxiesBucket + "/",
                  //     "NameModifier": fileNameUnique,
                  //     "appName": "sanchaneventsDev",
                  //     "clientFileId": uniqueId,
                  //     "imagesbucket": window?.site?.common?.resourceBucket,
                  //     "myBucket": window?.site?.common?.sourceBucket,
                  //     "path": uploadFilePath + fileNameUnique,
                  //     "proxiesBucket": window?.site?.common?.proxiesBucket
                  //   },
                  //   "presets": [{
                  //     "profile": "HD",
                  //     "resolution": "720p",
                  //     "Aspect ratio": "16:9",
                  //     "Preset": "720P-6.5Mbps",
                  //     "NameModifier": "_720p",
                  //     "duration": "In Min",
                  //     "dimensions": "1280×720",
                  //     "name": "High Definition",
                  //     "price": [
                  //       {
                  //         "from": 0,
                  //         "to": 0,
                  //         "cost": 4,
                  //         "currency": "$"
                  //       }
                  //     ]
                  //   }]
                  // }
                  setTimeout(function () {
                    // axios.post('https://1mgtsjq0kj.execute-api.us-east-1.amazonaws.com/latest/pushtoElemental?appname=${appname}&clientid=' + clientid,
                    //   pushtoElementalPayload
                    //   // { grandTotal }
                    // ).then(res => {
                        // console.log('Pushtoelementanal', res)

                    // })


                    const newFile = {
                      filename: fileNameUnique,
                      filepath: uploadFilePath,
                      fileid: uniqueId,
                      filetype: file.type,
                      filesize: file.size,
                      fileformat: fileExtension,
                      fileDuration: formattedDuration,
                      tags
                    };
                    return axios.post(
                      `${lambda}/clientContent?appname=${appname}&clientid=${clientid}`,
                      newFile
                    ).then(clientContentResponse => {
                      if (clientContentResponse.status === 200) {
                        return axios.get(
                          `${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}`
                        ).then(clientContentInfoResponse => {
                          if (clientContentInfoResponse.status === 200) {
                            const result = clientContentInfoResponse.data.result;
                            setVideoFiles(result);
                            navigate(location.pathname, { replace: true, state: null });
                            finalizeToast(progressToastId, file.name, true);
                            return result;
                          } else {
                            finalizeToast(progressToastId, file.name, false);
                            throw new Error('Failed to get user file info');
                          }
                        });
                      } else {
                        finalizeToast(progressToastId, file.name, false);
                        throw new Error('Failed to add user file');
                      }
                    });
                  }, 5000)
                }
              }
            })
            .onError((error) => {
              console.error(error);
              finalizeToast(progressToastId, file.name, false);
            });

          uploader.start();
        });

        return uploadPromise;

      } else if (file.type.startsWith('image/')) {
        // File is an image
        uploadFilePath = `images/client/${clientid}/files/${file.name.split('.')[0]}_image_${timestamp}.${fileExtension}`;
        bucket = window.site.common.resourceBucket; // Use resource bucket for images
        const data = {
          source_bucket: bucket, // Use the correct bucket based on file type
          sourcepath: uploadFilePath
        };
        const newFile = {
          filename: file.name.split('.')[0],
          filepath: uploadFilePath,
          fileid: uniqueId,
          filetype: file.type,
          filesize: file.size,
          fileformat: fileExtension,
          tags
        };
        const uploadPromise = axios.post(
          `${lambda}/uploadFiles?appname=${appname}`,
          data,
          { headers: { 'Content-Type': 'application/json' } }
        ).then(uploadUrlResponse => {
          if (uploadUrlResponse.data && uploadUrlResponse.data.result) {
            const uploadUrl = uploadUrlResponse.data.result;

            return axios.put(uploadUrl, file, {
              headers: {
                'Content-Type': file.type,
                'Cache-Control': 'no-cache'
              }
            }).then(uploadResponse => {
              if (uploadResponse.status === 200) {
                return axios.post(
                  `${lambda}/clientContent?appname=${appname}&clientid=${clientid}`,
                  newFile
                ).then(clientContentResponse => {
                  if (clientContentResponse.status === 200) {
                    return axios.get(
                      `${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}`
                    ).then(clientContentInfoResponse => {
                      if (clientContentInfoResponse.status === 200) {
                        const result = clientContentInfoResponse.data.result;
                        setVideoFiles(result);
                        navigate(location.pathname, { replace: true, state: null });
                        return result;
                      } else {
                        throw new Error('Failed to get user file info');
                      }
                    });
                  } else {
                    throw new Error('Failed to add user file');
                  }
                });
              } else {
                throw new Error('Failed to upload file to S3');
              }
            });
          } else {
            throw new Error('Failed to get upload URL');
          }
        });

        return toast.promise(
          uploadPromise,
          {
            pending: `Uploading ${file.name}...`,
            success: `${file.name} uploaded successfully!`,
            error: `${file.name} failed to upload.`,
          }
        );

      } else {
        // File type is neither image nor video
        toast.error('Unsupported file type');
        return;
      }
      //  console.log('Upload File Path:', uploadFilePath);

    });

    try {
      //const results = await Promise.all(uploadPromises);
      await Promise.all(uploadPromises);

    } catch (error) {
      console.error('Error during file upload:', error);
    }

  };
  const toastClose = (success) => {
    console.log("success",success)
    toast.update(success, {
      // render: success ? `${fileName} uploaded successfully!` : `${fileName} failed to upload.`,
      // type: success ? 'success' : 'error',  // Using 'success' and 'error' as strings
      autoClose: true,
      closeOnClick: true,
    });

  };
  const closeToast = () => {
   // console.log("toast",toast.message)
    setCloseClick(true)
  }
  function onConfirm() {
    setCloseClick(false)
    toastClose(true);
    if (location.state) {
      navigate(location.state, { replace: true, state: null }); // Clear state on component load
      
    }
    // navigate.reload()
    window.location.reload()
    //setFiles([""])
    // console.log("location.state", location.state)
    //return [location, navigate];
    
  }
  function closePopup() {
    setCloseClick(false)
  }
  const customCloseButtonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'red',
    fontSize: '16px',
    cursor: 'pointer',
  };
  const CustomCloseButton = () => (
    <button onClick={closeToast} style={customCloseButtonStyles}>
      <span className="material-symbols-outlined"><span className="tooltip">Close</span>close</span>
    </button>
  );
  const notify = () => toast("This is a toast with a custom close button!");

  return (
    <div id="page-top">
      <Navbar />
      <div className="inner-body">
        <div className="inner-wrapper">
          <Sidebar />
          <Content videoFiles={videoFiles} />
        </div>


        <div>
          <button onClick={notify}>Show Toast</button>
          <ToastContainer
            position="bottom-right"
          // closeButton={<CustomCloseButton />}

          />
        </div>
      </div>
      {closeClick &&
        // <SweetAlert show={closeClick}
        //   custom
        //   confirmBtnText="Yes"
        //   confirmBtnText="No"
        //   confirmBtnBsStyle="primary"
        //   title={"Are you sure cancel to upload!"}
        //   onConfirm={e => onConfirm()}
        // >
        // </SweetAlert>
        <Modal className="access-denied delete_popup" show={closeClick}>

                                <div className="modal-body">
                                    <div className="container">
                                        <button className="close-btn" onClick={e => closePopup()}><span className="material-icons">close</span></button>
                                        {/* <span className="material-icons access-denied-icon">delete_outline</span>
                                        <h3>Delete</h3>
                                        <p>This action cannot be undone.</p> */}
                                        <p>Are you sure you want to cancel the upload ?</p>
                                        <div className="popup-footer">
                                            <button className="fill_btn " onClick={e => onConfirm()}> Yes</button>
                                            <button className="fill_btn " onClick={e => closePopup()}> No</button>
                                        </div>
                                    </div>
                                </div>

                            </Modal>
        
        }
    </div>
  );
};

export default MainContent;
